import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
import { CSSProperties } from 'react';
import storage from "framework/src/StorageProvider";
import { CreditCardBlack, MortgageImgBlack, OtherBlack, PersonalLoanBlack } from "./assets";
import amPmConverter from "../../../components/src/AmPmConverter.web";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
interface IPlannerServiceDetails{
  "total_client": number,
  "client_percent": number,
  "client_operand": string,
  "active_services": number,
  "service_percent": number,
  "service_operand": string,
  "client_satisfaction": number,
  "rating_percent":number,
  "rating_operand": string
}

export interface AllResponseData {
  services_selected: false
}
interface BillingAddressResponseData{
  data: {
      id: string;
      type: string;
      attributes: {
          id: number;
          name: string;
          address: string;
          city: string;
          zipcode: string;
          country: string;
          account_id: number |string | null;
          wealth_planner_id: string | number;
      }
  }
}

interface AccountDetailsResponseData{
  data: {
      id: string;
      type: string;
      attributes: {
        wealth_planner_id: number | string,
        beneficiary_name: string,
        address: string,
        bank_name: null,
        bank_number: string,
        iban_number: string,
      }
  }
}
interface ServiceOverviewData{
  service_overview: [
    {
        name: string;
        price: number;
        percentage: string;
    }
  ]
}

interface IIncomeOverviewData {
  xaxis: string[],
  data: number[]
}

const IncomeOverviewMonthXAxisData = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
]


export interface IClient{
  "id": string,
  "type": string,
  "attributes": {
    "client_id": number,
    "name": string,
    "date": string,
    "request_status": string,
    "review": IReview,
    "status": string,
    "proposal_sent_status": boolean
  }
}
export interface IFilter{
  requestedStatus: string[],
  days:string,
  serviceStatus:string[],
  selectedServices:string[]
}

export interface TFilter {
  date:string;
  status:string;
}

export interface TResponseData{
  number: number,
  user: number,
  name: null | number,
  amount: number,
  date: string,
  status: string
}

export interface TResponse{
  data:TResponseData[];
  count:number;

}

export interface IClientCompletedService {
  "id": string,
  "type": string,
  "attributes": {
    "payment_status": boolean,
    "proposal_template_id": number,
    "name": string,
    "service": string,
    "date": string,
    "status": string,
    "step_description"?: string,
    "step_completion_date"?: string,
    "review": IReview,
    client_id: number

  }
}

export interface IClientInProgressService{
  "id": string,
  "name": string,
  "service": string,
  "date": string,
  "status": string,
  client_id: number
}

export interface IReview {
  "average": number,
  "reviews":
  {
    "id": number,
    "rating": number,
    "description": number,
    "date": string,
  }[],
}
interface Appointment {
  client_id: number,
  client: string,
  slot: {
    data: {
      id: string,
      type: string,
      attributes: {
        booking_date: string,
        plan_name: string,
        plan_duration: number,
        plan_benefits: Array<string>,
        video_meeting_id: string,
        starts_at: string,
        ends_at: string
      }
    }
  },
  wealth_planner_name: string
}
interface ISResponse  {
  data: IClientCompletedService
}
type ServiceResponse  =  ISResponse & IClientInProgressService;
interface Lean {
  reconnect :(payload : {
    app_token: string,
    reconnect_id: string,
    sandbox:string,
    callback: () => void,
  }) => void

  connect :(payload : {
    app_token: string,
    permissions: string[],
    sandbox:string,
    customer_id: string,
    callback?: (response: {
      success: boolean
    }) => void,
  }) => void

}
declare global {
  interface Window {
    Lean: Lean;
  }
}

interface PortfolioItem {
  id: number;
  name: string;
  balance: number;
  operand: string;
  capital_gain: number;
  entity: string;
  cost: number;
  portfolio_id: string;
  currency: string;
  price: string;
  symbol: string;
  difference: string;
  percentage: string;
}

interface Asset {
  id: number;
  ticker_id?: number;
  name: string;
  full_name: string;
  operand: string;
  currency: string;
  symbol: string;
  price?: string;
  difference: string;
  percentage: string;
  logo_url?: string | null;
  icon_url?: string | null;
  total_balance?: number;
  port_folio?: PortfolioItem[];
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  bankAccountOverview: any;
  cashOverview: any;
  SharesOverview: any;
  StocksOverview: any;
  CryptoCurrencyOverview: any;
  NFTOverview: any;
  PropertyOverview: any;
  PortableOverview: any;
  PensionOverview: any;
  token: any;
  errorMsg: string;
  loading: boolean;
  activeAsset: string;
  lastSyncTime: any;
  cashData: any;
  stockData: Asset[],
  cryptoData: {
    "id": number,
    "ticker_id": number,
    "name": string,
    "full_name": string,
    "operand": string,
    "currency": string,
    "symbol": string,
    "price": string,
    tickers: {
        "id": number,
        "ticker_name": string,
        "name": string,
        "provider_ticker": string,
        "asset_type": string,
        "currency": string,
        "price": string,
        "old_price": string,
        "logo": string,
        "difference": string,
        "percentage": number,
        "operand": string,
        "symbol": string
    }[],
    "difference":string,
    "percentage": number,
    "logo_url": string,
    "icon_url": string
}[];
  sharesData: {
    id: string;
    type: string;
    attributes: {
        name: string;
        number_of_shares: number;
        converted_new_price: string;
        balance: string;
        converted_price: string | number;
        profit: string;
        converted_currency_symbol: string;
        converted_currency: string;
        price_currency: string;
        new_price_currency: string;
        account_id: number;
        price: string;
        price_symbol: string | null;
        new_price: string;
        new_price_symbol: string | null;
        profit_percent: number | null;
        operand: string;
    };
  }[];
  propertyData: {
    id: string;
    type: string;
    attributes: {
        name: string;
        price_currency: string;
        mortgage_currency: string | null;
        location: string;
        tower_or_community: string | null;
        apartment_number: string;
        floor_number: number;
        bed_rooms: number;
        bath_rooms: number;
        account_id: number;
        market_value: number;
        purchase_date: string;
        martagage_loan: boolean;
        market_date: string;
        country: string | null;
        operand: string;
        converted_market_value: number;
        price: string;
        mortgage: string | null;
        area_size: number;
        converted_price: string;
        balance: string;
        converted_currency_symbol: string;
        converted_currency: string;
        converted_mortgage: string;
        mortgage_currency_symbol: string | null;
        images: {
            id: number;
            url: string;
            filename: string;
            content_type: string;
        }[];
        title_deeds: {
            id: number;
            url: string;
            filename: string;
            content_type: string;
        }[];
        status: {
            id: number;
            name: string;
        };
        sub_category: {
            id: number;
            name: string;
            image: {
                id: number;
                url: string;
                filename: string;
                content_type: string;
            };
        };
        property_mortgage: {
            id: number;
            amount: number;
            term_years: string;
            annual_interest_rate: number;
            mortgage_monthly_payments: number;
            start_date: string;
            first_loan_payment_date: string | null;
            property_id: number;
            created_at: string;
            updated_at: string;
            term_months: number;
        };
        profit_percentage: string;
        profit: number;
        liabilities_forms: {
            id: number;
            name: string;
            amount: number;
            term: string;
            months: number;
            annual_interest_rate: number;
            monthly_payments: number;
            start_date: string;
            first_payments: number;
            account_id: number;
            form_type: string;
            property_id: number;
            currency_type: string;
            converted_currency_type: string;
            converted_amount: string;
            converted_monthly_payments: number;
            portable_asset_id: number | null;
            created_at: string;
            updated_at: string;
        };
    };
  }[];
  portableData: any;
  pensionData: any;
  networthCount: any;
  duration: any;
  duration1: any;
  duration2: any;
  duration3: any;
  duration4: any;
  graphNetworth: any;
  BankAccountNewData: {
    "id": number,
    "image": string,
    "name": string,
    "connection_type": string,
    "converted_balance": number,
    "converted_symbol": string,
    "currency_name": string,
    "original_currency": string,
    account_number: string
  }[];
  expandBank: any;
  expandCash: any;
  expandShares: any;
  expandStocks: any;
  expandCrypto: any;
  expandProperty: any;
  expandPortable: any;
  expandPension: any;
  activeImage: any;
  dropdownVisiblity: any;
  selectedCurrency: any;
  currencyList: any;
  stockBtn: any;
  cryptoBtn: any;
  searchTicker: any;
  searchTicker1: any;
  tickerList: any;
  cryptotickerList: any;
  ticker_id: any;
  ticker_id1: any;
  ticker_quantity: any;
  ticker_quantity1: any;
  portfolioList: any;
  walletList: {
    "id": number,
    "display_name": string,
    "name": string,
    "resource_type": string,
    "auth_type": string,
    "logo": string,
  }[];
  selectedWallet: any;
  vezgoData: any;
  connectionStart: any;
  connectionURL: any;
  userVezgoData: any;
  selectedOption: any;
  selectedOptionLine: any;
  value: number | null;
  hover: number;
  Dateofcreate: any;
  activeSection: any;
  pendingData:any;
  pendingData1:any;
  acceptId:any;
  openSuccessModal:boolean;
  openModel: boolean,
  formPage: string,
  pendingDataItem: string,
  IsPendingDataReject : boolean,
  weekOffset: number;
  weekDays: {
    day: string,
    date:string
  }[];
  weekDetails: string;
  selectedDate: string;
  scheduledCalls: {
    client: string | null,
    time: string,
    join_call: boolean,
    client_id:number,
    displayTime: string;
  }[]
  plannerServiceDetails: any,
  incomeOverviewDuration: number,
  ActivityClientSelect : string,
  incomeOverviewData: IIncomeOverviewData,
  incomeAnchorToolTipEl: HTMLSpanElement| null,
  incomeAnchorToolTipElone: HTMLSpanElement| null,
  incomeAnchorToolTipElClient: HTMLSpanElement| null,
  direction: string,
  language:string,
  clients:IClient[];
  paymentTransaction:any;
  transactionFilter:TFilter;
  currentPage: number;
  totalRecords:number;
  filter : IFilter;
  clientActivity: string,
  getClientActivityData: any,
  activeTab:string;
  serviceStatistics: any;
  focustList: {
    "id": string,
    "attributes": {
      "name": string,
    }}[],
  clientServices: IClientInProgressService[]  | IClientCompletedService[],
  perPageClient:number,
  currentTickerPage:number,
  loadingCryptoData: boolean,
  currentStockTickerPage:number,
  currentStockListPage:number,
  loadingStockData: boolean,
  loadingStockData2: boolean,
  lean: Lean,
  customerId: string,
  currentWalletPage: number,
  expandCommodity: string,
  commodityOverview: {
    "id": number,
    "name": string,
    "arabic_name": string,
    "count": number,
    "symbol": string,
    "balance": string
  },
  liabilityOverview: {
    "id": number,
    "name": string,
    "arabic_name": string,
    "count": number,
    "symbol": string,
    "balance": string
  },
  commodityData: {
    attributes:{
      name:string,
      commodity_type:string,
      converted_currency_symbol:string,
      converted_amount: number,
      quantity: string
    }
  }[],
  liabilityData: {
      id: string,
      name:string,
      form_type:string,
      converted_currency_symbol:string,
      converted_amount: number,
      remaining_payment: number
    }[],
  expandLiability: string,
  appointments:Appointment[],
  currentAppointment:Appointment | null,
  buttonText:string,
  showToast:boolean,
  plannerInfo: {
    name:string,
    email:string,
    image:string
  },
  addAddressFormDetails: { name: string, address: string, city: string, zip_code: string, country: string, accountId: number | null },
  addAccountDetails:{ beneficiary_name: string, address: string, bank_name?: null,bank_number: string, iban_number: string,wealth_planner_id: number | null | string}
  paymentMethodAddDetails: { cardName: string, expiry: any, cardNumber: any, cvv: any },
  countryName: { label: string; value: string }[];
  address: string;
  countries: any;
  allCountriesLocation: any;
  apiCallingStage: boolean;
  countryPage: number;
  monthlyGoal: {
    name: string;
    value: number;
    percentage: string;
    color: string;
  }[];
  color: string[];
  billingAddress: string;
  editAddressStep: string;
  joinCallEnable:boolean;
  editAccountStep: string;
  clientTransactionService:{
    number: number,
    user: number,
    name: string |null,
    amount: number,
    date: string,
    status: string
   }[];
   totalLists: number;
   currentListPage:number,
   isLoader:boolean,
   BookingTime:string,
   bookingDuration:string|number,
   isNetWorthGraphLoade:boolean
   isToggledForViewAssets:boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addAddressCallId:string="";
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  refreshDashboardCallId: string = "";
  refreshNetworthCallId: string = "";
  cashDataCallId: string = "";
  SharesDataCallId: string = "";
  propertyCallId: string = "";
  portableAssetDataCallId: string = "";
  pensionDataCallId: string = "";
  BankAccountCallId: string = "";
  getCurrencyListCallId: string = "";
  setNewCurrencyCallId: string = "";
  tickerListCallId: string = "";
  cryptotickerListCallId: string = "";
  walletListDataCallId: string = "";
  vezgoTokendataCallId: string = "";
  addTickerCallId: string = "";
  addCryptoTickerCallId: string = "";
  stockDataCallId: string = "";
  cryptoDataCallId: string = "";
  userVezgonCallId: string = "";
  getPendingCallId: string ="";
  userAcceptDeclineCallId: string ="";
  VezgoWalletRegistrationCallId:string="";
  getScheduledClientCallListId:string="";
  plannerServiceDetailsCallId:string ="";
  getClientsAPICallID:string= "";
  getFocusListAPICallId:string=""
  getServiceRequestCallId: string = "";
  incomeOverviewDataCallId: string ="";
  getClientActivityRequestCallId:string= "";
  cryptotickerListSearchCallId: string = "";
  stocktickerListSearchCallId: string = "";
  getCustomerIdAPIId: string = "";
  getReconnectAPICallId: string="";
  getCommodityAPICallId: string ="";
  getLiabilityAPICallId:string ="";
  VideoCallApiCallid:string="";
  MeetingApiCallid:string="";
  intervalId: ReturnType<typeof setInterval> | number = 0;

  getUserProfileAPICallId:string = ""
  getCountryCallId:string="";
  getPieChartDataCallId:string = "";
  getBillingAddressCallId: string = ""
  editAddressCallId: string = "";
  addAccountCallId: string = "";
  getAccountCallId: string = "";
  editAccountCallId: string ="";
  getTransactionCallId:string="";
  getInvestmentAPICallId:string="";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: "",
      bankAccountOverview: "",
      cashOverview: "",
      SharesOverview: "",
      StocksOverview: "",
      CryptoCurrencyOverview: "",
      NFTOverview: "",
      PropertyOverview: "",
      PortableOverview: "",
      PensionOverview: "",
      errorMsg: "",
      token: localStorage.getItem('authToken'),
      loading: false,
      activeAsset: "",
      lastSyncTime: '',
      currencyList: '',
      cashData: '',
      stockData: [],
      cryptoData: [],
      sharesData: [],
      propertyData: [],
      portableData: '',
      pensionData: '',
      networthCount: '',
      duration: '',
      duration1: 'duration',
      duration2: 'duration',
      duration3: 'duration',
      duration4: 'active-duration',
      graphNetworth: '',
      selectedCurrency: '$USD',
      BankAccountNewData: [],
      expandBank: 'faq-block',
      expandCash: 'faq-block',
      expandShares: 'faq-block',
      expandStocks: 'faq-block',
      expandCrypto: 'faq-block',
      expandProperty: 'faq-block',
      expandPortable: 'faq-block',
      expandPension: 'faq-block',
      activeImage: 'subarrowicon',
      dropdownVisiblity: 'state-dropdown',
      stockBtn: 'ticker',
      cryptoBtn: 'ticker',
      searchTicker: '',
      searchTicker1: '',
      tickerList: '',
      cryptotickerList: [],
      ticker_id:'',
      ticker_id1:'',
      ticker_quantity:'',
      ticker_quantity1:'',
      portfolioList:'',
      walletList:[],
      selectedWallet:'',
      vezgoData:'',
      connectionStart:'',
      connectionURL:'',
      userVezgoData:'',
      selectedOption:this.selectedOption.Monthly,
      selectedOptionLine:'',
      value: 2,
      hover: -1,
      Dateofcreate: null,
      activeSection: 'Pending',
      pendingData: [],
      pendingData1: '',
      acceptId: '',
      direction:'ltr',
      language:"English",
      openSuccessModal: false,
      openModel: false,
      formPage: "Billing",
      pendingDataItem: '',
      IsPendingDataReject: false,
      weekOffset: 0,
      weekDays: [],
      weekDetails: "",
      selectedDate: new Date().toISOString().split('T')[0],
      scheduledCalls: [],
      plannerServiceDetails:null,
      serviceStatistics: {},
      incomeOverviewDuration: this.IncomeOverviewDuration.Monthly,
      ActivityClientSelect: this.ActivityClientSelect.All,
      incomeOverviewData: {
        xaxis: IncomeOverviewMonthXAxisData,
        data: []
      },
      incomeAnchorToolTipEl: null,
      incomeAnchorToolTipElone: null,
      incomeAnchorToolTipElClient: null,
      clients:[],
      currentPage: 1,
      totalRecords: 0,
      filter:{
        requestedStatus:[],
        selectedServices:[],
        serviceStatus:[],
        days:""
      },
      activeTab:"pending",
      clientActivity: '',
      getClientActivityData:[],
      focustList: [],
      clientServices: [],
      currentTickerPage:1,
      perPageClient: window.location.pathname.toLowerCase().includes('client') ? 7 :4,
      loadingCryptoData:true,
      loadingStockData2: true,
      currentStockTickerPage:1,
      currentStockListPage: 1,
      loadingStockData: true,
      lean: {
        reconnect: (payload) => payload.callback(),
        connect: (payload) => payload
      },
      customerId:"",
      currentWalletPage: 1,
      expandCommodity: "faq-block",
      expandLiability: "faq-block",

      commodityOverview: {
        id: 1,
        name: "",
        arabic_name: "",
        count: 0,
        symbol: "",
        balance: "0",
      },
      commodityData: [],
      liabilityData: [],
      liabilityOverview: {
        id: 1,
        name: "",
        arabic_name: "",
        count: 0,
        symbol: "",
        balance: "0",
      },
      appointments:[],
      currentAppointment:null,
      buttonText:"Video Call",
      showToast:false,
      joinCallEnable:false,
      plannerInfo:{
        name:"",
        image:"",
        email:""
      },
      countryName:[],
      addAddressFormDetails: { name: "", address: "", city: "", zip_code: "", country: "", accountId: null },
      addAccountDetails:{ beneficiary_name: "", address: "", bank_number: "", iban_number: "",wealth_planner_id: null },
      paymentMethodAddDetails: { cardName: '', expiry: '', cardNumber: '', cvv: '' },
      address: '',
      countries:'',
      allCountriesLocation: [],
      apiCallingStage: false,
      countryPage: 0,
      monthlyGoal:[],
      color:['#3a3fff','#00b72a','#0f628c','#00ecdc','#9800fe'],
      billingAddress: '',
      editAddressStep: "",
      editAccountStep: "",
      clientTransactionService:[],
      totalLists:0,
      paymentTransaction: [],
      transactionFilter:{date:"",status:""},
      currentListPage:1,
      isLoader:true,
      BookingTime:'',
      bookingDuration:'',
      isNetWorthGraphLoade:false,
      isToggledForViewAssets:false,
    };

   
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    let language = await getStorageData('language');
    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ direction: currentLanguage ,language: language});

    this.getPieChartData();
    this.getTransactionDetails();
    this.getAccountDetails();
    this.getBillingAddressData();
    this.getCountriesForAddress();
    this.getClientActivtyList()
    this.getCurrencyList();
    this.tickerList();
    this.cryptotickerSearchList();
    this.portfolioListData();
    this.walletListData();
    this.vezgoTokendata();
    this.getPendingList();
    this.handleWalletRegistration(window.location.search);
    this.getServiceRequestList(this.state.selectedOption);
    this.getWeek();
    this.getScheduledClientCallList();
    this.getPlannerServiceDetails();
    this.getIncomeOverviewList();
    this.getPlannerServiceDetails()
    this.getClients(1,this.state.filter);
    this.getFocusList();
    this.getCustomerId();
    this.GetCustomerDetails();
    this.intervalId = window.setInterval(()=>{this.checkAppointments()}, 60000);
    this.getUserProfile();
    this.functioncheck()
    this.refreshDashboard()
    this.refreshNetworthGraph()
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = localStorage.getItem('authToken');
      this.setState({ token: token, loading: true }, () => {

        this.refreshDashboard();
        this.refreshNetworthGraph();
      });
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.refreshDashboardAndCashData(message, responseJson2, apiRequestCallId)
    this.portableAndProperty(message, responseJson2, apiRequestCallId)
    this.manageVezgoTokenResponce(message, responseJson2, apiRequestCallId)
    this.manageUserVezgoResponce(message, responseJson2, apiRequestCallId)
    this.manageCurrencyResponse(responseJson2, apiRequestCallId, message)
    this.managePendingResponce(responseJson2, apiRequestCallId, message)
    this.manageServiceRequestResponce(responseJson2, apiRequestCallId, message)
    this.manageTickerListResponse(responseJson2, apiRequestCallId)
    this.cryptotickerListResponce(responseJson2, apiRequestCallId)
    this.cryptotickerListSearchResponce(responseJson2, apiRequestCallId)
    this.walletListDataResponce(responseJson2, apiRequestCallId)
    this.manageStockData(responseJson2, apiRequestCallId)
    this.manageCryptoData(responseJson2, apiRequestCallId)
    this.manageScheduledCallListResponse(responseJson2, apiRequestCallId)
    this.handleClientServiceDetailsData(responseJson2,apiRequestCallId)
    this.manageIncomeOverviewListResponse(responseJson2,apiRequestCallId)
    this.manageClientActivityRequestResponce(responseJson2, apiRequestCallId, message)
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.pensionDataCallId.length > 0 &&
      apiRequestCallId === this.pensionDataCallId) {
      if (responseJson2?.data) {
        this.setState({ pensionData: responseJson2.data })
      }
    }
    if (responseJson2 && apiRequestCallId == this.addTickerCallId) {
      if (responseJson2.tickers) {
        this.setState({ openSuccessModal: true, ticker_quantity: '', ticker_id: '' },
      () => {
        setTimeout(() => {
          this.goToStockBackPage();
        }, 2000);
      })
    }
  }
    if (responseJson2?.tickers && apiRequestCallId == this.addCryptoTickerCallId) {
      this.setState({   openSuccessModal: true, ticker_quantity1: '', ticker_id1: '' })

    }

    if (responseJson2?.message && apiRequestCallId == this.userAcceptDeclineCallId) {

      this.setState({ acceptId: responseJson2 })
      this.setState({ pendingData: [] })
      this.getPendingList()
      this.dialogModelCl()
      this.getClientActivtyList()

    }
    this.handleWalletRegistrationResponse(apiRequestCallId,responseJson2)
    this.handleGetClientResponse(responseJson2,apiRequestCallId);
    this.handleGetFocusListResponse(responseJson2,apiRequestCallId);
    this.stocktickerListSearchResponce(responseJson2,apiRequestCallId)
    this.handleGetCustomerIdResponse(responseJson2,apiRequestCallId)
    this.handleGetReconnectIdResponse(responseJson2,apiRequestCallId);
    this.handleGetCommodityResponse( responseJson2, apiRequestCallId)
    this.handleGetLiabilityResponse( responseJson2, apiRequestCallId)
    this.handleVideoResponse( responseJson2, apiRequestCallId)
    this.handleMeetingResponse( responseJson2, apiRequestCallId)



    this.handleUserProfileResponse( apiRequestCallId, responseJson2 )
    this.handleCountryResponse(responseJson2, apiRequestCallId);
    this.handleAddBillingAddress(responseJson2, apiRequestCallId,message);
    this.handleGetBillingAddress(responseJson2, apiRequestCallId,message);
    this.handleEditBillingAddress(responseJson2, apiRequestCallId,message);
    this.handleServiceOverviewResponse(responseJson2, apiRequestCallId,message);
    this.handlegetAccountDetails(responseJson2, apiRequestCallId,message);
    this.handleAddAccountDetails(responseJson2, apiRequestCallId,message);
    this.handleEditAccountDetails(responseJson2, apiRequestCallId,message);
    this.handleGetTransactionResponse(responseJson2, apiRequestCallId);
    this.handleInvestmentResponse(responseJson2, apiRequestCallId);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  
  selectCurrency = async (value: any) => {
    this.setState({ selectedCurrency: value.symbol + value.currency_type })
    const formData = new FormData();
    formData.append("currency_id", value.id);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setNewCurrencyCallId = requestMessage.messageId;
    const header = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/add_currency_to_account"
    );
    requestMessage.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStringDashboard = (keyvalue: string) => {
    return configJSON.languageListDashboard[this.state.language][keyvalue]
  }

  getStringStocks = (keyvalue: string) => {
    return configJSON.languageListStocks[this.state.language][keyvalue]
  }
  
  getStringCrypto = (keyvalue: string) => {
    return configJSON.languageListCrypto[this.state.language][keyvalue]
  }

  GetCustomerDetails = async () => {
    const headers = {
      token: localStorage.getItem("auhtToken"),
      "Content-Type": "application/json",
  };
  const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.VideoCallApiCallid = requestMsg.messageId;
  requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_dashboard/planner_dashboard_home/scheduled_calls`
  );
  requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMsg.id, requestMsg);
 
  }

  getInvestments = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvestmentAPICallId = requestMessage.messageId;
    const header = {
      token: await storage.get("authToken")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_apiintegrationstocksshares/flanks_investments"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  
  manageCurrencyResponse = async (responseJson2: any, apiRequestCallId: any, message: Message) => {
    if (responseJson2 &&
      apiRequestCallId === this.getCurrencyListCallId
    ) {
      const responseJson6 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      for (let x of responseJson6?.list) {

        if (x.id == responseJson6.selection) {
          this.setState({ selectedCurrency: x.symbol + x.currency_type })
        }
      }
      this.setState({ currencyList: responseJson6 })
    }

    if (responseJson2 && apiRequestCallId === this.setNewCurrencyCallId) {
      const responseJson7 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson7) {
        this.refreshDashboard();
        this.refreshNetworthGraph();
        this.getCryptoData();
        this.setState({
          currentStockListPage: 1,
          stockData: []
        }, () => this.getStockData())
      }
    }
  }
  getJoinButtonStyle = (callJoinValue: boolean, activeStyle: CSSProperties, defaultStyle: CSSProperties) => {
    return callJoinValue ? activeStyle : defaultStyle;
  };
  getCurrencyList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrencyListCallId = requestMessage.messageId;
    const header = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/currency_list"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleDropdown = () => {
    if (this.state.dropdownVisiblity == 'state-dropdown') {
      this.setState({ dropdownVisiblity: 'state-dropdown active' })
    } else {
      this.setState({ dropdownVisiblity: 'state-dropdown' })
    }
  }
  handleGlobalDropdown = () => {
    if (this.state.dropdownVisiblity === 'state-dropdown active') {
      this.setState({ dropdownVisiblity: 'state-dropdown' });
    }
  };
  //////////////////////
  getPendingList = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("auhtToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPendingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_proposal_generation/service_proposals/list_pending_requests"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  
  getScheduledClientCallList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getScheduledClientCallListId = requestMessage.messageId;
    const header = {
      token: await storage.get("auhtToken")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/planner_dashboard_home/scheduled_calls?date=${this.state.selectedDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  manageScheduledCallListResponse(responseJson2: any, apiRequestCallId: string) {
    if (responseJson2 &&  apiRequestCallId === this.getScheduledClientCallListId) {

      if ("index" in responseJson2 && responseJson2.index.length > 0) {
        const respData = responseJson2?.index?.map((detail: {
          client: string | null,
          client_id: number,
          slot: {
            data: {
              attributes: {
                starts_at: string | null,
                ends_at: string | null,
              }
            }
          }

        }) => ({ 
          client: detail.client, 
          time: amPmConverter(detail.slot?.data?.attributes?.starts_at as string) + " - " + amPmConverter(detail.slot?.data?.attributes?.ends_at as string), 
          client_id: detail?.client_id, 
          join_call: false,
          displayTime: this.displayTimeConverter(detail.slot?.data?.attributes?.starts_at as string, detail.slot?.data?.attributes?.ends_at as string) 
        }))

      this.setState({ scheduledCalls: respData })
    } else {
      this.setState({ scheduledCalls: [] })
    }
  }
}
     
    


  displayTimeConverter = (startsAtUTC: string, endsAtUTC: string) => {
    const startsAtDate = new Date(`1970-01-01T${startsAtUTC}:00Z`);
    const endsAtDate = new Date(`1970-01-01T${endsAtUTC}:00Z`);

    const formattedStartTimeBegin = moment(startsAtDate).format("hh:mm A");
    const formattedEndTimeComplete = moment(endsAtDate).format("hh:mm A");
    return `${formattedStartTimeBegin} - ${formattedEndTimeComplete}`
  }

  managePendingResponce = async (responseJson2: any, apiRequestCallId: any, message: Message) => {
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPendingCallId.length > 0 &&
      apiRequestCallId === this.getPendingCallId) {
      if (responseJson2.requests) {

        this.setState({ pendingData: responseJson2.requests })
        const updatedPlanners = this.state.pendingData.map((planner:any) => {
 const newTimeString= this.formatBookingDateTime(planner.created_at)
          return { ...planner, created_at: newTimeString}
          });
          this.setState({pendingData:updatedPlanners})
        }
      } else {
        this.setState({ pendingData1: responseJson2.message })
      }
    }

    formatBookingDateTime(dateTimeString:any) {
      // Create a new Date object from the UTC string
      const localDate = new Date(dateTimeString);
  
      // Define the month names array
      const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"];
  
      // Extract the local date components
      const monthName = monthNames[localDate.getMonth()]; // Full month name (e.g., "June")
      const day = localDate.getDate();
      const year = localDate.getFullYear();
  
      // Extract the local time components
      let hours = localDate.getHours();
      const minutes = localDate.getMinutes().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12; // Convert to 12-hour format
  
      // Format the string
      const formattedDateTime = `${monthName} ${day}, ${year} at ${hours}:${minutes} ${ampm}`;
  
      return formattedDateTime;
  }


  handleGraphSelectClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({
      incomeAnchorToolTipElone: event.currentTarget,
    })
  };

  handleGraphOverviewDropdown = (val: string) => {
    this.closeIncomeOverviewPopover();
    this.setState({ selectedOption: val});
    this.getServiceRequestList(val)
  };

  selectedOption: any = {
    Monthly: 'month',
    Yearly: 'year'
  }
 
  getServiceRequestList = async (val: string) => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem('auhtToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceRequestCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_dashboard/planner_dashboard_home/service_statistics?duration=${val}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  manageServiceRequestResponce = async (responseJson2: any, apiRequestCallId: any, message: Message) => {

    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      // this.getServiceRequestCallId.length > 0 &&
      apiRequestCallId === this.getServiceRequestCallId) {

      const years = responseJson2?.service_statistics?.map((data: any) => data.year);
      const ongoingServices = responseJson2?.service_statistics?.map((data: any) => data.ongoing_services);
      const completedServices = responseJson2?.service_statistics?.map((data: any) => data.completed_services);
      this.setState({
        serviceStatistics:{year :years ,ongoingService: ongoingServices ,completedService: completedServices}
      })

    }
  }
  getIncomeOverviewList = async () => {
    const duration = this.state.incomeOverviewDuration === this.IncomeOverviewDuration.Monthly
      ? "month" : "year"
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.incomeOverviewDataCallId = requestMessage.messageId;
    const header = {
      token: await storage.get("auhtToken")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/planner_dashboard_home/income_overview?duration=${duration}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  manageIncomeOverviewListResponse(responseJson2: any, apiRequestCallId: string) {
    if (responseJson2 &&  apiRequestCallId === this.incomeOverviewDataCallId) {
      if ("income_overview" in responseJson2 && Object.keys(responseJson2.income_overview).length > 0) {
        if (this.state.incomeOverviewDuration === this.IncomeOverviewDuration.Yearly) {
          this.setState({ incomeOverviewData: {
              xaxis: Object.keys(responseJson2.income_overview)
                .sort((aIndex, bIndex) => parseInt(String(aIndex)) - parseInt(String(bIndex))),
              data: (Object.keys(responseJson2.income_overview) as (keyof typeof responseJson2.income_overview)[])
                .sort((aIndex, bIndex) => parseInt(String(aIndex)) - parseInt(String(bIndex)))
                .map((keyData) => responseJson2.income_overview[keyData])
            }})
        } else {
          this.setState({ incomeOverviewData: {
              xaxis: IncomeOverviewMonthXAxisData,
              data: (Object.keys(responseJson2.income_overview) as (keyof typeof responseJson2.income_overview)[])
                .sort((aIndex, bIndex) => parseInt(String(aIndex)) - parseInt(String(bIndex)))
                .map((keyData) => responseJson2.income_overview[keyData])
            }})
        }
      } else {
        this.setState({ incomeOverviewData: {
            xaxis: [],
            data: []
} })
          }
            }
  }

  dialogModelCl = () => {
    this.setState({ openModel: !this.state.openModel, IsPendingDataReject: false })
  }

  onAccept = () => {
    this.setState({ openModel: !this.state.openModel })
  }
  toggleEditPage = (item: string) => {
    this.setState({ formPage: item})
  }
  HendlePendindata = (item: string) => {
    this.setState({
      pendingDataItem: item
    })
    this.onAccept()
  }
  HendlePendindataReject = (item: string) => {
    this.setState({
      pendingDataItem: item,
      IsPendingDataReject: true
    })
    this.onAccept()
  }
  userAcceptDecline = async (accountId: any, status: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userAcceptDeclineCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("auhtToken"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let httpBody: any = {

      "status": status,
      "account_id": accountId

    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_formapprovalworkflow/wealth_planners/accept_reject_request"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  //////////////////////////////////////////////////////////////// 
  newFunction2 =(responseJson2: any)=>{
    if (responseJson2?.error) {
      toast.error(this.getStringDashboard("NetworthGraphNotLoaded"))
      this.setState({ isNetWorthGraphLoade:false })
    }else{
      const convertedData = {
        ...responseJson2,
        data: {
            ...responseJson2.data,
            networth_graph: responseJson2.data.networth_graph.map((item:any) => ({
                ...item,
                networth: typeof item.networth === "string" ? parseFloat(item.networth) : item.networth
            }))
        }
    };
    
    localStorage.setItem("networth", responseJson2.Web_networth);
      localStorage.setItem("assets", responseJson2.Web_assets);
      localStorage.setItem("debts", responseJson2.Web_debts);
      this.setState({ 
        graphNetworth: convertedData?.data,
        lastSyncTime: responseJson2.last_synced_on, 
        isNetWorthGraphLoade:false,
       });
    }
  }
  newFunction = async (responseJson2: any) => {  
    if (responseJson2.AssetOverview) {
      this.setState({isLoader:false})
      this.setState({
        bankAccountOverview: responseJson2.AssetOverview[0], cashOverview: responseJson2.AssetOverview[1],
        SharesOverview: responseJson2.AssetOverview[2], StocksOverview: responseJson2.AssetOverview[3], CryptoCurrencyOverview: responseJson2.AssetOverview[4],
        NFTOverview: responseJson2.AssetOverview[5], PropertyOverview: responseJson2.AssetOverview[6], PortableOverview: responseJson2.AssetOverview[7],
        PensionOverview: responseJson2.AssetOverview[8],
        commodityOverview: responseJson2.AssetOverview[10],
        liabilityOverview: responseJson2.AssetOverview[9],
      })
    document.dispatchEvent(new CustomEvent("sidebarPropsUpdated", { detail: true }));
    }
    if(responseJson2.reconnect_id){
      this.refreshLeanAccount(responseJson2.reconnect_id)
    }
  }
  refreshDashboardAndCashData = async (message: Message, responseJson2: any, apiRequestCallId: any) => {
    if (
      responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.refreshDashboardCallId.length > 0 &&
      apiRequestCallId === this.refreshDashboardCallId
    ) {
      this.newFunction(responseJson2);
    }
    if (
      responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.refreshNetworthCallId.length > 0 &&
      apiRequestCallId === this.refreshNetworthCallId
    ) {
      this.newFunction2(responseJson2);
    }
    

    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.cashDataCallId.length > 0 &&
      apiRequestCallId === this.cashDataCallId) {
      if (responseJson2.data) {
        this.setState({ cashData: responseJson2.data })
      }
    }

    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.SharesDataCallId.length > 0 &&
      apiRequestCallId === this.SharesDataCallId) {
      if (responseJson2.data) {
        this.setState({ sharesData: responseJson2.data })

      }
    }
  }

  portableAndProperty = async (message: Message, responseJson2: any, apiRequestCallId: any) => {
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.BankAccountCallId.length > 0 &&
      apiRequestCallId === this.BankAccountCallId) {
      if (responseJson2.bank_accounts) {
        this.setState({ BankAccountNewData: responseJson2.bank_accounts.splice(0,5) })

      }
    }


    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.propertyCallId.length > 0 &&
      apiRequestCallId === this.propertyCallId) {
      if (responseJson2.data) {
        this.setState({ propertyData: responseJson2.data })

      }
    }

    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.portableAssetDataCallId.length > 0 &&
      apiRequestCallId === this.portableAssetDataCallId) {
      if (responseJson2.data) {
        this.setState({ portableData: responseJson2.data })

      }
    }
  }


  manageDuration = async (durationData: any) => {
    this.setState({ duration: durationData })
    if (durationData == '1') {
      this.setState({ duration1: "active-duration", duration2: "duration", duration3: "duration", duration4: "duration" })
    }
    if (durationData == '3') {
      this.setState({ duration2: "active-duration", duration1: "duration", duration3: "duration", duration4: "duration" })

    }
    if (durationData == '6') {
      this.setState({ duration3: "active-duration", duration2: "duration", duration1: "duration", duration4: "duration" })

    }
    if (durationData == '12') {
      this.setState({ duration4: "active-duration", duration2: "duration", duration3: "duration", duration1: "duration" })

    }
    setTimeout(() => {
      this.refreshNetworthGraph();
    }, 500);
  }

  dateOfBirth = (date: Date | null) => {
    this.setState({ Dateofcreate: date });
  };


  getCashData = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cashDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/cashes?page=1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (this.state.cashOverview.count > 0) {
      this.setStatusForAsset("cashOverview")
    }
    else {
      toast.dismiss()
      toast.error(this.getStringDashboard("NoAsset"))
    }
  }
  manageStockData = async (responseJson2: {tickers: Asset[]}, apiRequestCallId: string) => {
    if (responseJson2 && apiRequestCallId == this.stockDataCallId) {
      if (responseJson2.tickers) {
        this.setState({ stockData: [...this.state.stockData, ...responseJson2.tickers], currentStockListPage: this.state.currentStockListPage+1 })

      }
      this.setState({
        loadingStockData2: false
      })
    }
  }

  manageCryptoData = async (responseJson2: any, apiRequestCallId: any) => {
    if (responseJson2 && apiRequestCallId == this.cryptoDataCallId) {
      if (responseJson2.tickers) {
        this.setState({ cryptoData: responseJson2.tickers })

      }
      this.setState({ loadingCryptoData: false })

    }
  }
  manageVezgoTokenResponce = async (message: any, responseJson2: any, apiRequestCallId: any) => {
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.vezgoTokendataCallId.length > 0 &&
      apiRequestCallId === this.vezgoTokendataCallId) {
      if (responseJson2.data) {
        this.setState({ vezgoData: responseJson2 })
      }
    }
  }
  manageUserVezgoResponce = async (message: any, responseJson2: any, apiRequestCallId: any) => {

    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userVezgonCallId.length > 0 &&
      apiRequestCallId === this.userVezgonCallId) {
      if (responseJson2.data) {
        this.setState({ userVezgoData: responseJson2 })
      }
    }
  }
  getStockData = async () => {
    this.setState({
      loadingStockData2: true,
    })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stockDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_apiintegrationstocksshares/user_tickers?page=${this.state.currentStockListPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getInvestments();
    if (this.state.StocksOverview.count > 0) {
      this.setStatusForAsset("StocksOverview")
    }
    else{
      if(!this.state.loadingStockData2){
        toast.dismiss()
        toast.error(this.getStringDashboard("NoAsset"))
      }
    }
  }

  getCryptoData = async () => {
    this.setState({
      loadingCryptoData: true
    })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cryptoDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_apiintegrationstocksshares/users_crypto?page=1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (this.state.CryptoCurrencyOverview.count > 0) {
      this.setStatusForAsset("CryptoCurrencyOverview")
    }
    else {
      if(!this.state.loadingCryptoData){
        toast.dismiss()
        toast.error(this.getStringDashboard("NoAsset"))
      }
    }
  }

  getBankAccountData = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.BankAccountCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/bank_forms"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (this.state.bankAccountOverview.count > 0) {
      this.setStatusForAsset("bankAccountOverview")
    }
    else {
      toast.dismiss()
      toast.error(this.getStringDashboard("NoAsset"))
    }
  }

  getSharesData = async () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.SharesDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/shares?token=${this.state.token}&page=1`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (this.state.SharesOverview.count > 0) {
      this.setStatusForAsset("SharesOverview")
    }
    else {
      toast.dismiss()
      toast.error(this.getStringDashboard("NoAsset"))
    }
  }

  getPropertyData = async () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.propertyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/properties?page=1`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (this.state.PropertyOverview.count > 0) {
      this.setStatusForAsset("PropertyOverview")
    }
    else {
      toast.dismiss()
      toast.error(this.getStringDashboard("NoAsset"))
    }
  }

  getPortableAssetData = async () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.portableAssetDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/portable_assets?token=${this.state.token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (this.state.PortableOverview.count > 0) {
      this.setStatusForAsset("PortableOverview")
    }
    else {
      toast.dismiss()
      toast.error(this.getStringDashboard("NoAsset"))
    }
  }

  getPensionData = async () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pensionDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/pensions`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (this.state.PensionOverview.count > 0) {
      this.setStatusForAsset("PensionOverview")
    }
    else {
      toast.dismiss()
      toast.error(this.getStringDashboard("NoAsset"))
    }
  }



  setStatusForAsset = async (name: string) => {
    this.cashBankPortableExpand(name);
    this.sharePropertyPensionExpand(name);
    this.commodityLiabilityExpand(name);

  }

  cashBankPortableExpand = async (name: string) => {
    if (name == 'bankAccountOverview') {
      if (this.state.expandBank == 'faq-block active') {
        this.setState({ expandBank: "faq-block", activeAsset: "" })
      }
      else {
        this.setState({
          activeAsset: "bankAccountOverview",expandLiability: "faq-block",expandCommodity:"faq-block", expandBank: "faq-block active", expandCash: "faq-block", expandPension: "faq-block"
          , expandPortable: "faq-block", expandProperty: "faq-block", expandShares: "faq-block", expandStocks: "faq-block", expandCrypto: 'faq-block',
        })
      }
    }

    if (name == 'cashOverview') {
      if (this.state.expandCash == 'faq-block active') {
        this.setState({ expandCash: "faq-block", activeAsset: "" })
      }
      else {
        this.setState({
          activeAsset: "cashOverview", expandCash: "faq-block active", expandBank: "faq-block", expandPension: "faq-block"
          , expandPortable: "faq-block", expandProperty: "faq-block",expandLiability: "faq-block", expandShares: "faq-block", expandStocks: "faq-block", expandCrypto: 'faq-block',
        })
      }
    }

    if (name == 'PortableOverview') {
      if (this.state.expandPortable == 'faq-block active') {
        this.setState({ expandPortable: "faq-block", activeAsset: "" })
      }
      else {
        this.setState({
          activeAsset: "PortableOverview", expandLiability: "faq-block",expandPortable: "faq-block active", expandBank: "faq-block", expandPension: "faq-block"
          , expandCash: "faq-block", expandProperty: "faq-block", expandShares: "faq-block", expandStocks: "faq-block", expandCrypto: 'faq-block',
        })
      }
    }
    
  }

  commodityLiabilityExpand = async (name: string) => {
    if (name == 'CommodityOverview') {
      if (this.state.expandCommodity == 'faq-block active') {
        this.setState({ expandCommodity: "faq-block", activeAsset: "" })
      }
      else {
        this.setState({
          activeAsset: "CommodityOverview",expandLiability: "faq-block",expandCommodity:"faq-block active", expandBank: "faq-block", expandCash: "faq-block", expandPension: "faq-block"
          , expandPortable: "faq-block", expandProperty: "faq-block", expandShares: "faq-block", expandStocks: "faq-block", expandCrypto: 'faq-block',
        })
      }
    }
    if (name == 'LiabilityOverview') {
      if (this.state.expandLiability == 'faq-block active') {
        this.setState({ expandLiability: "faq-block", activeAsset: "" })
      }
      else {
        this.setState({
          activeAsset: "LiabilityOverview",expandLiability: "faq-block active",expandCommodity:"faq-block", expandBank: "faq-block", expandCash: "faq-block", expandPension: "faq-block"
          , expandPortable: "faq-block", expandProperty: "faq-block", expandShares: "faq-block", expandStocks: "faq-block", expandCrypto: 'faq-block',
        })
      }
    }
  }

  sharePropertyPensionExpand = async (name: string) => {
    if (name == 'PensionOverview') {
      if (this.state.expandPension == 'faq-block active') {
        this.setState({ expandPension: "faq-block", activeAsset: "" })
      }
      else {
        this.setState({
          activeAsset: "PensionOverview",expandCommodity:"faq-block",expandLiability: "faq-block",expandPension: "faq-block active", expandCash: "faq-block", expandBank: "faq-block"
          , expandPortable: "faq-block", expandProperty: "faq-block", expandShares: "faq-block", expandStocks: "faq-block", expandCrypto: 'faq-block',
        })
      }
    }

    if (name == 'PropertyOverview') {
      if (this.state.expandProperty == 'faq-block active') {
        this.setState({ expandProperty: "faq-block", activeAsset: "" })
      }
      else {
        this.setState({
          activeAsset: "PropertyOverview", expandCommodity:"faq-block",expandLiability: "faq-block",expandProperty: "faq-block active", expandCash: "faq-block", expandBank: "faq-block"
          , expandPortable: "faq-block", expandPension: "faq-block", expandShares: "faq-block", expandStocks: "faq-block", expandCrypto: 'faq-block',
        })
      }
    }

    if (name == 'SharesOverview') {
      if (this.state.expandShares == 'faq-block active') {
        this.setState({ expandShares: "faq-block", activeAsset: "" })
      }
      else {
        this.setState({
          activeAsset: "SharesOverview",expandLiability: "faq-block", expandShares: "faq-block active", expandCash: "faq-block", expandBank: "faq-block"
          , expandPortable: "faq-block",expandCommodity:"faq-block", expandPension: "faq-block", expandProperty: "faq-block", expandStocks: "faq-block", expandCrypto: 'faq-block',
        })
      }
    }

    this.stockOverviewCheck(name);
    this.cryptoOverviewCheck(name);

  }

  stockOverviewCheck = (name: any) => {
    if (name == 'StocksOverview') {
      if (this.state.expandStocks == 'faq-block active') {
        this.setState({ expandStocks: "faq-block", activeAsset: "" })
      }
      else {
        this.setState({
          activeAsset: "StocksOverview", expandPension: "faq-block active", expandCash: "faq-block", expandBank: "faq-block"
          , expandPortable: "faq-block", expandProperty: "faq-block", expandShares: "faq-block", expandStocks: "faq-block active", expandCrypto: 'faq-block',
        })
      }
    }
  }

  cryptoOverviewCheck = (name: any) => {
    if (name == 'CryptoCurrencyOverview') {
      if (this.state.expandCrypto == 'faq-block active') {
        this.setState({ expandCrypto: "faq-block", activeAsset: "" })
      }
      else {
        this.setState({
          activeAsset: "CryptoCurrencyOverview", expandPension: "faq-block active", expandCash: "faq-block", expandBank: "faq-block"
          , expandPortable: "faq-block", expandProperty: "faq-block", expandShares: "faq-block", expandStocks: "faq-block", expandCrypto: "faq-block active",
        })
      }
    }
  }

  handleRefresh=()=>{
    this.refreshDashboard(true)
    this.refreshNetworthGraph()
  }


  refreshNetworthGraph = async () => {
    this.setState({
      isNetWorthGraphLoade:true
    })
    let _duration = this.state.duration || 12
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.refreshNetworthCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_cfdashboard6/dashboards/networth?duration=" + _duration
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  refreshDashboard = async (isRefresh?: boolean) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.refreshDashboardCallId = requestMessage.messageId;

    if (isRefresh) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_dashboard/dashboards/refresh?duration=12&bank_refresh=true"
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_dashboard/dashboards/refresh?duration=12"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  redirectToNext = () => {
    if (this.state.stockBtn == 'ticker') {
      this.props.navigation.navigate('AddStockTicker')
    } else {
      this.props.navigation.navigate('Portfolio')

    }

  }

  getCommodityData = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommodityAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/commodities`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (this.state.commodityOverview.count > 0 ) {

      this.setStatusForAsset("CommodityOverview")
    }
    else {
      toast.dismiss()
      toast.error(this.getStringDashboard("NoAsset"))
    }
  }

  getLiabilityData = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLiabilityAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/liabilities_forms`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (this.state.liabilityOverview.count  >  0) {
      this.setStatusForAsset("LiabilityOverview")
    }
    else {
      toast.dismiss()
      toast.error(this.getStringDashboard("NoAsset"))
    }
  }

  redirectToSomeRoute = (navRoute: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), navRoute);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  redirectToNext1 = () => {
    if (this.state.cryptoBtn == 'ticker') {
      this.props.navigation.navigate('AddCryptoTicker')
    } else {
      this.props.navigation.navigate('Wallet')

    }

  }
  searchTicker = (value: any) => {
    this.setState({ searchTicker: value }, () => {
      this.stockTickerSearchList()
    })
  }

  searchTicker1 = (value: any) => {
    this.setState({ searchTicker1: value }, () => {
      this.cryptotickerSearchList()
    })
  }
   formatCurrency = (value:any) => {
    if (typeof value === 'number') {
      return value.toLocaleString();
    } else if (typeof value === 'string') {
      let numValue = parseFloat(value.replace(/,/g, ''));
      if (!isNaN(numValue)) {
        return numValue.toLocaleString();
      }
    }
    return value;
  };

  tickerQuantityUpdate = (value: any) => {
    let func1 = this.formatCurrency(value)
    this.setState({ ticker_quantity: func1 })
  }
  removeCommas = (value:any) => {
    return value.replace(/,/g, '');
  };
  tickerQuantityUpdate1 = (value: any) => {
    this.setState({ ticker_quantity1: value })
  }
  tickerList = () => {
    if(this.state.searchTicker){
      return
    }
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.tickerListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_apiintegrationstocksshares/index?page=${this.state.currentStockTickerPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  stockTickerSearchList = () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stocktickerListSearchCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_apiintegrationstocksshares/index?page=1&search=${this.state.searchTicker}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      currentStockTickerPage:1
    })
  }


  cryptotickerList = () => {
    if(this.state.searchTicker1){
      return
    }
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cryptotickerListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_apiintegrationstocksshares/cypto_list?page=${this.state.currentTickerPage}&search=${this.state.searchTicker1}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  cryptotickerSearchList = () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cryptotickerListSearchCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_apiintegrationstocksshares/cypto_list?page=${1}&search=${this.state.searchTicker1}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      currentTickerPage:1
    })
  }

  cryptotickerListResponce = async (responseJson2: any, apiRequestCallId: any) => {
    if (responseJson2 &&
      apiRequestCallId === this.cryptotickerListCallId
    ) {
      if (responseJson2.tickers) {
        let tickers;
      
          tickers = responseJson2.tickers;
        
        this.setState({ cryptotickerList: [...this.state.cryptotickerList,...tickers] ,currentTickerPage: this.state.currentTickerPage + 1})
      }
      else {
        this.setState({ cryptotickerList: []})
      }
    }

  }
  cryptotickerListSearchResponce = async (responseJson2: any, apiRequestCallId: any) => {
    if (responseJson2 &&
      apiRequestCallId === this.cryptotickerListSearchCallId
    ) {
      if (responseJson2.tickers) {
        let tickers;
      
          tickers = responseJson2.tickers;
        
        this.setState({ cryptotickerList: tickers ,currentTickerPage: this.state.currentTickerPage + 1})
      }
      else {
        this.setState({ cryptotickerList: []})
      }
    }

  }

  stocktickerListSearchResponce = async (responseJson2: any, apiRequestCallId: string) => {
    if (responseJson2 &&
      apiRequestCallId === this.stocktickerListSearchCallId
    ) {
      if (responseJson2.tickers) {
        let tickers;
      
          tickers = responseJson2.tickers;
        
        this.setState({ tickerList: tickers ,currentStockTickerPage: this.state.currentStockTickerPage + 1})
      }
      else {
        this.setState({ tickerList: []})
      }
    }

  }
  manageTickerListResponse = async (responseJson2: any, apiRequestCallId: any) => {
    if (responseJson2 &&
      apiRequestCallId === this.tickerListCallId
    ) {
      if (responseJson2.tickers) {
        let tickers;
      
          tickers = responseJson2.tickers;
        
        this.setState({ tickerList: [...this.state.tickerList,...tickers] ,currentStockTickerPage: this.state.currentStockTickerPage + 1})
      }
      else {
        this.setState({ tickerList: []})
      }
    }

  }

  addTicker = () => {
    const removeFunc=this.removeCommas(this.state.ticker_quantity)
    this.setState({ticker_quantity: removeFunc} , ()=>{
    if (!this.state.ticker_quantity || isNaN(this.state.ticker_quantity) || this.state.ticker_quantity <= 0) {
      toast.error(this.getStringDashboard("TickerQuentityError"));
      return;
    }
    if (!this.state.ticker_id) {
      toast.error(this.getStringDashboard("StockError"));
      return;
    }

    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addTickerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_apiintegrationstocksshares/set_ticker?ticker_id=${this.state.ticker_id}&quantity=${this.state.ticker_quantity}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
})  
  }


  addCryptoTicker = () => {
    if (!this.state.ticker_quantity1 || isNaN(this.state.ticker_quantity1) || this.state.ticker_quantity1 <= 0) {
      toast.error(this.getStringDashboard("TickerQuentityError"));
      return;
    }
    if (!this.state.ticker_id1) {
      toast.error(this.getStringDashboard("StockError"));
      return;
    }

    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCryptoTickerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_apiintegrationstocksshares/set_crypto?ticker_id=${this.state.ticker_id1}&quantity=${this.state.ticker_quantity1}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  portfolioListData = () => {
    let Data = [{
      "id": "1",
      "name": "Fidelity Invest.",
    },
    {
      "id": "2",
      "name": "Nutmeg.",
    },
    {
      "id": "3",
      "name": "Vanguard.",
    }]


    this.setState({ portfolioList: Data })
  }

  walletListData = () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.walletListDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cfapiintegrationcrypto/crypto_wallet?page=${this.state.currentWalletPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  walletListDataResponce = async (responseJson2: {
    wallets: { id: number; display_name: string; name: string; resource_type: string; auth_type: string; logo: string; }[]
  }, apiRequestCallId: string) => {
    if (responseJson2 &&
      apiRequestCallId === this.walletListDataCallId
    ) {
      if (responseJson2.wallets) {

        this.setState({ walletList: [...this.state.walletList ,... responseJson2.wallets],currentWalletPage: this.state.currentWalletPage+1 })
      }
      else {
        this.setState({ walletList: []})
      }
    }

  }
  vezgoTokendata = () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.vezgoTokendataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cfapiintegrationcrypto/crypto_wallet/create_vezgo_token`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  addCryptoWallet = () => {
    if (!this.state.selectedWallet) {
      toast.error(this.getStringDashboard("SelectWallet"))
    } else {
      localStorage.setItem("provider_id", this.state.selectedWallet.id)
      const connectionURL = `https://connect.vezgo.com/connect/${this.state.selectedWallet.name}?client_id=${this.state.vezgoData.client_id}&token=${this.state.vezgoData.data.token}&redirect_uri=${window.location.origin}/Wallet`
      window.location.href = connectionURL;

    }
  }
  handleWalletRegistration(searchString: string) {
    const urlParams = new URLSearchParams(searchString);
    const account = urlParams.get('account');
    const code = urlParams.get('code');
    const provider_id = localStorage.getItem("provider_id");
    if (account && code && provider_id) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.VezgoWalletRegistrationCallId = requestMessage.messageId;
      const header = {
        "Content-Type": "application/json",
        token: this.state.token
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      let httpBody = {
        "data": {
          "account_id": account,
          "code": code,
          "provider_id": provider_id
        }
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_cfapiintegrationcrypto/crypto_wallet"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleWalletRegistrationResponse(apiRequestCallId: string, responseJson2: { measage: string }) {
    if (apiRequestCallId === this.VezgoWalletRegistrationCallId) {
      if (responseJson2.measage) {
        this.setState({
          openSuccessModal: true
        })
      }
    }
  }


  dialogSuccessPaperStyle = {
    width: 'max-content',
    backgroundColor: '#395D6B',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };

  goToDashboard() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg)
  }

  getWeek = (weekOffset: number = 0) => {
    const day = new Date().getDay();
    let start = new Date();
    const weekDays = [];
    start.setDate(start.getDate() - ((day + 6) % 7) + 7 * weekOffset);
    if (start.getDate() === 1 && day === 1 && weekOffset === 0) {
      start.setDate(0);
    }
    this.setState({weekDetails: new Intl.DateTimeFormat("en-US", {
        month: "long",
        year: "numeric",
      }).format(start)})
    for (let i = 0; i < 7; i++) {
      const date = new Date(start).setDate(start.getDate() + i);
      weekDays.push({
        day: new Date(date).getDate().toString(),
        date: new Date(date).toISOString().split('T')[0]
      });
    }
    this.setState({ weekDays })
  };

  handlePreviousWeek(){
    this.setState(
      (prevState) => ({ weekOffset: prevState.weekOffset - 1 }),
      () => this.getWeek(this.state.weekOffset)
    );
  }

  handleNextWeek(){
    this.setState(
      (prevState) => ({ weekOffset: prevState.weekOffset + 1 }),
      () => this.getWeek(this.state.weekOffset)
    );
  }

  handleDateSelected(selectedDate: string){
    this.setState({ selectedDate }, () => this.getScheduledClientCallList())
  }
  
  getPlannerServiceDetails = async () => {
      const header = {
        token: await storage.get("auhtToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.plannerServiceDetailsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getClientOverviewUrl
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getClientOverviewMethodUrl
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClientServiceDetailsData(responseData:IPlannerServiceDetails,apiRequestCallId:string){
    if(apiRequestCallId === this.plannerServiceDetailsCallId){

      this.setState({
        plannerServiceDetails: responseData
      })

    }
  }

  handleIncomeOverviewDropdown = (duration: number) => {
    this.closeIncomeOverviewPopover();
    this.setState({ incomeOverviewDuration: duration}, () => this.getIncomeOverviewList());
  };

  handleIncomeSelectClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({
      incomeAnchorToolTipEl: event.currentTarget,
    })
  };

  closeIncomeOverviewPopover() {
    this.setState({
      incomeAnchorToolTipEl: null,
      incomeAnchorToolTipElone: null,
      incomeAnchorToolTipElClient: null
    })
  }

  IncomeOverviewDuration = {
    Monthly: 1,
    Yearly: 2
  }
  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null
  }) => {
    const { contentType, method, endPoint, body } = data;
    const dashboardRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    dashboardRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token: localStorage.getItem("auhtToken")
      })
    );

    dashboardRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    dashboardRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && dashboardRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(dashboardRequestMessage.id, dashboardRequestMessage);
    return dashboardRequestMessage.messageId;
  };


  getClients = async (page:number,filters:IFilter) => {
    let fitlerString= `tab=${this.state.activeTab}&`;
    if(filters.requestedStatus.length){

      let requestedStatusString = "";
      for(let status of filters.requestedStatus){
        requestedStatusString+= `request_status[]=${status.toLowerCase()}&`
      }
      fitlerString += `${requestedStatusString}&`
    }
    if(filters.serviceStatus.length){
      let serviceStatusString = "";
      for(let status of filters.serviceStatus){
        serviceStatusString+= `status[]=${status}&`
      }
      fitlerString += `${serviceStatusString}&`
    }
    if(filters.days){
      fitlerString += `&date=${filters.days.toLowerCase()}&`
    }
    if(filters.selectedServices.length){
      let selectedServiceString = "";
      for(let focusId of filters.selectedServices){
        selectedServiceString+= `focus_plan_ids[]=${focusId}&`
      }
      fitlerString += `${selectedServiceString}&`
    }

    this.getClientsAPICallID = await this.apiCall({
      contentType:  "application/json",
      method: "GET" + '',
      endPoint: `bx_block_proposal_generation/service_proposals/get_clients?page=${page}&per_page=${this.state.perPageClient}&` + fitlerString+'',
      body: null
    });
  }
  
  handleGetClientResponse(response:
    {
      clients:{
        data: IClient[]
      },
      services: ServiceResponse,
      total_count:number
    },requestCallId:string){
    if(requestCallId === this.getClientsAPICallID){
      if(this.state.activeTab === "pending"){

        this.setState({
          clients:  response.clients.data ,
          totalRecords: response.total_count,
          clientServices:[]
        })
      }
      if(this.state.activeTab === "completed"){

        this.setState({
          clientServices:  response.services.data as unknown  as IClientCompletedService[],
          totalRecords: response.total_count,
          clients:[]
        })
      }
      if(this.state.activeTab === "in_progress"){
        
        this.setState({
          clientServices:  response.services as unknown  as IClientInProgressService[] ,
          totalRecords: response.total_count,
          clients:[]
        })
      }
      
    }
  }

  handlePageChange = (page:number) =>{
    this.setState({
      currentPage: page,
    })
    this.getClients(page,this.state.filter)
  }

  handleFilter = (filter: IFilter) => {
    this.setState({
      currentPage: 1,
      filter
    });
    this.getClients(1,filter)
  }

  handleTransactionPageChange = (page:number) =>{
    this.setState({
      currentListPage: page,
    })
    this.getTransactionDetails(page,this.state.transactionFilter)
  }

  handleTransactionFilter = (filter: TFilter) => {
    this.setState({
      currentListPage: 1,
      transactionFilter:filter
    });
    this.getTransactionDetails(1,filter)
  }

  
  handleTabChange = (tab:string) => {
    let initialFilter = {
      requestedStatus: [],
      days:"",
      selectedServices:[],
      serviceStatus: []
    }
    if(this.state.activeTab === tab){
      return
    }
    this.setState({
    })
    this.setState({
      currentPage: 1,
      filter: initialFilter,
      activeTab:tab
    },()=>{
      this.getClients(1,initialFilter)
    })

    
  }

  isTabActive = (tab:string) => this.state.activeTab === tab? true :false

  handleClientActivityDropdownChange = (event: any) => {
    this.setState({ clientActivity: event.target.value });
  };


  handleIncomeSelectClickActivty = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({
      incomeAnchorToolTipElClient: event.currentTarget,
    })
  };
  handleIncomeOverviewDropdownActivity = (duration: string) => {
    this.closeIncomeOverviewPopover();
    this.setState({ ActivityClientSelect: duration}, () => this.getClientActivtyList());
  };
  ActivityClientSelect: any = {
    ConsultationCalls: 'Consultation calls',
    AcceptedRequest: 'Accepted Request',
    PendingRequest: 'Pending Request',
    All:'All'
  }

  getClientActivtyList = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem('auhtToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientActivityRequestCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_dashboard/planner_dashboard_home/today_activities?filter=${this.state.ActivityClientSelect}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  manageClientActivityRequestResponce = async (responseJson2: any, apiRequestCallId: any, message: Message) => {

    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      // this.getClientActivityRequestCallId.length > 0 &&
      apiRequestCallId === this.getClientActivityRequestCallId) {
        this.setState({
          getClientActivityData: responseJson2.activities.map((activity:any) => ({
            ...activity,
            filter: this.state.ActivityClientSelect
          }))
        });
    }
  }
  formatIncomeOverviewValue = (value: number) => {
    if (value >= 1000) {
      return String(value/1000).concat('k')
    } else {
      return value.toString()
    }
  }
  
  getFocusList = async () => {
    this.getFocusListAPICallId = await this.apiCall({
      contentType:  "application/json",
      method: "GET" + '',
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/focus_list",
      body: null
    });
  }

  handleGetFocusListResponse(response: {focus:{
      data: {
        "id": string,
        "attributes": {
          "name": string,
        }}[]
    }},requestCallId:string){
    if(requestCallId === this.getFocusListAPICallId){
      this.setState({
        focustList:  response.focus.data,
      })
    }
  }
  setTickerId = (tickerId:any) =>{
    this.setState({
      ticker_id1: tickerId
    })
  }

  setStockTickerId = (tickerId:any) =>{
    this.setState({
      ticker_id: tickerId
    })
  }


  goToBackPage() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CryptoSelection");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg)
  }
  goToClientPage = async (clientId:number) =>  {
     await storage.set("clientId",clientId)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientDetails");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

 

  goToStockBackPage() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "StockSelection");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg)
  }

  getCustomerId = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": await  storage.get('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomerIdAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_customer_id`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleGetCustomerIdResponse(response:{
    customer_id:string
  },apiCallId:string){
    if(apiCallId === this.getCustomerIdAPIId){
      this.setState({
        customerId: response.customer_id
      })
    }
  }

  getReconnectId = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": await storage.get('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReconnectAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_reconnect_id_sdk_call"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  refreshLeanAccount = (reconnectId:string) => {
    this.state.lean.reconnect({
      app_token: configJSON.leanConnectionAuthToken,
      reconnect_id: reconnectId,
      sandbox: "false",
      callback:this.getReconnectId,
    });      
  }

  handleGetReconnectIdResponse(response:{
    reconnect_id:string
  },apiCallId:string){
    if(apiCallId === this.getReconnectAPICallId){
      if(response.reconnect_id){
        this.refreshLeanAccount(response.reconnect_id)
      }
    }
  }
  getButtonStyle=(details:any,
    currentAppointment: Appointment | null,
    activeStyle: CSSProperties,
    defaultStyle: CSSProperties)=>{

      return (details?.client_id === currentAppointment?.client_id && details?.time === amPmConverter(currentAppointment?.slot?.data?.attributes?.starts_at as string) + " - " + amPmConverter(currentAppointment?.slot?.data?.attributes?.ends_at as string) ? {...activeStyle, cursor:"pointer"} : defaultStyle);
  }
  isButtonDisabled = (
    details: any,
    currentAppointment: Appointment | null
  ): boolean => {
    return (details?.client_id === currentAppointment?.client_id && details?.time === amPmConverter(currentAppointment?.slot?.data?.attributes?.starts_at as string) + " - " + amPmConverter(currentAppointment?.slot?.data?.attributes?.ends_at as string)? false : true);
  };
  handleGetCommodityResponse(response:{
    data:  {
      attributes:{
        name:string,
        commodity_type:string,
        converted_currency_symbol:string,
        converted_amount: number,
        quantity: string
      }
    }[]
  },apiCallId: string){

    if(apiCallId === this.getCommodityAPICallId){
     this.setState({
      commodityData: response.data.slice(0, 5)
     });
    }

  }
  handleGetLiabilityResponse(response:{
    data:  {
      attributes:{
        id: string,
        name:string,
        form_type:string,
        converted_currency_symbol:string,
        converted_amount: number,
        remaining_payment: number
      }[]
    }
  },apiCallId: string){
    if(apiCallId === this.getLiabilityAPICallId){
     this.setState({
      liabilityData: response && response.data && response.data.attributes
     });
    }
    
  }
  
  handleVideoResponse(response:any,apiCallId: string){

    if(apiCallId === this.VideoCallApiCallid){
      this.setState({appointments:response.index})
        this.checkAppointments();
    }
    
  }

  handleInvestmentResponse(response:{errors: string[]},apiCallId: string){
    if(apiCallId === this.getInvestmentAPICallId && response.errors?.length > 0){
      toast.error(this.getStringDashboard("UpdatedStockError"))
    }
  }

  handleMeetingResponse(response:any,apiCallId: string){

    if(apiCallId === this.MeetingApiCallid){
      
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "VideoCall");
  message.addData(
    getName(MessageEnum.NavigationPropsMessage),
    this.props
  );
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { meetingId:response.booking.data.attributes.video_meeting_id,
    authToken:response.videosdk_token,
    userName:this.state.currentAppointment?.wealth_planner_name,
    wealthPlanneName:this.state.currentAppointment?.client,
    BookingTime:this.state.BookingTime,
    bookingDuration:this.state.bookingDuration
  })
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message)
    }
    
  }
  VideoCallClient(response:any){  
    if(response.call_join){
      const BookingTime:string=response.appointment_time?.split('at')[1].trim().split(' ')[0]

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "VideoCall");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { meetingId:response.meet,
      authToken:response.meet_token,
      userName:response.name,
      wealthPlanneName:this.state.currentAppointment?.client,
      BookingTime:BookingTime,
      bookingDuration:this.state.bookingDuration
    })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message)
    }
  
    
  }
  checkAppointments = () => {
    const now = new Date();
    const currentUTCTime = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

    const currentAppointment = this.state.appointments.find(appointment => {
      // Parse the booking_date
      const newDate = moment(`${appointment?.slot?.data.attributes.booking_date} ${now.getUTCHours()}:${now.getUTCMinutes()}`, "DD/MM/YYYY HH:mm")
      const appointmentDate = new Date(newDate.utc().format());
      
      // Check if the appointment date is today
      const isToday = now.getUTCFullYear() === appointmentDate.getUTCFullYear() &&
                      now.getUTCMonth() === appointmentDate.getUTCMonth() &&
                      now.getUTCDate() === appointmentDate.getUTCDate();

      if (!isToday) {
        return false;
      }

      // Parse the booking_starting_time
      const [startHourString, startMinute] = appointment?.slot?.data.attributes.starts_at.split(':');
      const startHour = parseInt(startHourString, 10);
      const appointmentStart = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), startHour, parseInt(startMinute, 10), 0, 0));

      const [endHourString, endMinute] = appointment?.slot?.data.attributes.ends_at.split(':');
      const endHour = parseInt(endHourString, 10);
      const appointmentEnd = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), endHour, parseInt(endMinute, 10), 0, 0));

      const appointmentStartTime = appointmentStart.getTime();
      const appointmentEndTime = appointmentEnd.getTime();

      const withinAppointmentTimeRange = currentUTCTime >= (appointmentStartTime - 5 * 60 * 1000) && currentUTCTime <= appointmentEndTime;
      return withinAppointmentTimeRange;
    });

    this.setState({ currentAppointment: currentAppointment || null, 
      BookingTime:currentAppointment?currentAppointment.slot.data.attributes.starts_at:'',
      bookingDuration:currentAppointment?currentAppointment.slot.data.attributes.plan_duration:'' });
    
    if (this.state.currentAppointment) {
      this.setState({ showToast: true });
      this.setState({ joinCallEnable: !this.state.joinCallEnable });
    }
};

  
  handleToastClose=()=>{
    this.setState({showToast:false})
  }

  handleVideoCall=(details:any)=>{
 if (this.state.currentAppointment ) {
  if(details.client_id === this.state.currentAppointment?.client_id && details.time === amPmConverter(this.state.currentAppointment?.slot?.data?.attributes?.starts_at) + " - " + amPmConverter(this.state.currentAppointment?.slot?.data?.attributes?.ends_at)){
    const headers = {
      token: localStorage.getItem("auhtToken"),
    };
    
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.MeetingApiCallid = requestMsg.messageId;
    const AccountID =this.state.currentAppointment?.client_id ;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_appointment_management/appointments/get_video_call_details?for=planner&account_id=${AccountID}`
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
     }
  }

  }

  getLiabilityImg(type:string){
    if(type === "mortgage"){
      return MortgageImgBlack
    }
    if(type === "personal_loans"){
      return PersonalLoanBlack
    }
    if(type === "credit_card"){
      return CreditCardBlack
    }
    return OtherBlack
  }

  

  handleUserProfileResponse(apiCallId:string,responseJson: {
    data: {
      attributes:{
        name: string,
        email: string,
        image: string
      }
    }
  }){
    if(apiCallId === this.getUserProfileAPICallId){
      this.setState({
        plannerInfo: responseJson.data.attributes
      })
      if(responseJson?.data?.attributes?.name == null){
        this.props.navigation.navigate("CreateAccount");
      }
    }
  }

  getUserProfile = async () => {
    this.getUserProfileAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/show_profile",
      body: null,
      contentType:"application/json"
    });
  }

  goToProfilePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PlannerProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }
//Dropdown for countries
callCountry = async () => {
  if (this.state.apiCallingStage) {
    this.setState({ apiCallingStage: false }, () => {
      this.getCountriesForAddress();
    })
  }
}

handleCountryResponse = (responseJson: AllResponseData[], apiRequestCallId: string) => {
  
  if (
    responseJson &&
    apiRequestCallId === this.getCountryCallId
  ) {
    if (responseJson.length > 1) {
      const countries = responseJson.map((value: any) => (
        { value: value.attributes.id, label: value.attributes.name }
      ));
      this.setState((prevState) => ({
        countries: [...prevState.countries, ...responseJson],
        allCountriesLocation: [...prevState.allCountriesLocation, ...responseJson],
        countryName: [...prevState.countryName, ...countries]

      }), () => {
        this.setState({ apiCallingStage: true })
      });
      this.callCountry()
    }

    }
  }

  handleAddBillingAddress(responseJson2: BillingAddressResponseData, apiRequestCallId: string,message: { id: string }) {
    // add Billing Address
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.addAddressCallId) {
      if (responseJson2?.data) {
        this.props.navigation.navigate('Payment')
        this.getBillingAddressData()
      }
    }
  }
  handleGetBillingAddress(responseJson2: BillingAddressResponseData, apiRequestCallId: string,message: { id: string }) {
    // get Billing Address
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getBillingAddressCallId) {
      if (responseJson2?.data) {
        const { name, address, city, zipcode, country, id } = responseJson2.data.attributes
        this.setState({ addAddressFormDetails: { name, address, city, zip_code: zipcode, country, accountId: id }, editAddressStep: "edit" })
      }
      else{
        this.setState({editAddressStep:"initial"})
      }
    }
  }
  handleEditBillingAddress(responseJson2: BillingAddressResponseData, apiRequestCallId: string, message: { id: string }) {
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.editAddressCallId) {
      if (responseJson2?.data) {
        this.props.navigation.navigate('Payment')
        this.getBillingAddressData()
      }
    }
  }
  handleAddAccountDetails(responseJson2: AccountDetailsResponseData, apiRequestCallId: string,message: { id: string }) {
    // add Account Details
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.addAccountCallId) {
      if (responseJson2?.data) {
        this.props.navigation.navigate('Payment')
        this.getAccountDetails()
      }
    }
  }
  handlegetAccountDetails(responseJson2: AccountDetailsResponseData, apiRequestCallId: string,message: { id: string }) {
    // add Account Details
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getAccountCallId) {
        if (responseJson2?.data) {
          const { beneficiary_name, address, bank_number, iban_number} = responseJson2.data.attributes
          this.setState({ addAccountDetails: { beneficiary_name, address, bank_number, iban_number, wealth_planner_id:responseJson2.data.id}, editAccountStep: "edit" })
        }
        else{
          this.setState({editAccountStep:"initial"})
        }
    }
  }
  handleEditAccountDetails(responseJson2: AccountDetailsResponseData, apiRequestCallId: string,message: { id: string }) {
    // add Account Details
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.editAccountCallId) {
      if (responseJson2?.data) {
        this.props.navigation.navigate('Payment')
        this.getAccountDetails()
      }
    }
  }
  handleServiceOverviewResponse(responseJson2: ServiceOverviewData, apiRequestCallId: string, message: { id: string }){
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getPieChartDataCallId) {
      if (responseJson2?.service_overview) {
        this.setState({ monthlyGoal: responseJson2?.service_overview.map((i:any,index:number) => { return {name:i.name,value: i.price, percentage: i.percentage, color: this.state.color[index] } }) })
      }
    }
  }
  //api calling
  getCountriesForAddress = async () => {
    const requestMessageForCountries = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryCallId = requestMessageForCountries.messageId;

    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_countries?page=${this.state.countryPage + 1}`
    );
    this.setState({ countryPage: this.state.countryPage + 1 })

    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessageForCountries.id, requestMessageForCountries);
  }
  //Add adress
  updateAddAddressDetails = async (name: any, value: any) => {
    let newValue = value;
    if(name === 'country'){
      newValue= value.label
    }
    this.setState((prevState) => ({
      addAddressFormDetails: {
        ...prevState.addAddressFormDetails,
        [name]: newValue,
      },
    }));
    
  };
  validateAddressForm = () => {
    const { name, address, city, zip_code, country } = this.state.addAddressFormDetails;
    if (!name) {
      toast.error('Full Name is required.');
      return false;
    }
    else if (!/^[a-zA-Z ]+$/g.test(name)) {
      toast.error('Only alphabetical characters are allowed.');
      return false;
    }
    if (!address) {
      toast.error('Address is required.');
      return false;
    }
    if (!city) {
      toast.error('City is required.');
      return false;
    }
    if (!/^[a-zA-Z\s]+$/.test(city)) {
      toast.error('City name must contain only letters.');
      return false;
    }
    if (!zip_code) {
      toast.error('Zip Code is required.');
      return false;
    }
    if (zip_code.length >9) {
      toast.error('Zip Code should be less than 9 digits.');
      return false;
    }
    if (!country) {
      toast.error('Country is required.');
      return false;
    }
    return true;
  }
  validateAccountInfo = () => {
    const { beneficiary_name, address, bank_number, iban_number } = this.state.addAccountDetails;
  
    if (!beneficiary_name) {
      toast.error('Beneficiary Name is required.');
      return false;
    }
    if (!address) {
      toast.error('Address is required.');
      return false;
    }
    if (!bank_number) {
      toast.error('Account Number is required.');
      return false;
    }
    if (!iban_number) {
      toast.error('IBAN Number is required.');
      return false;
    }
    return true;
  };
  
  handleSaveClick = () => {
    if (this.validateAddressForm()) {
      this.state.editAddressStep === "edit" ? this.setUpdateAddress() : this.setNewAddress();
    }
  }
  handleSaveClickConfirm = () => {
    if (this.validateAccountInfo()) {
      this.state.editAccountStep === "edit" ? this.editAccountDetails() : this.setNewAccountDetails();
    }
  }
  updateAccountDetails = async (name: any, value: any) => {
   
    this.setState((prevState) => ({
      addAccountDetails: {
        ...prevState.addAccountDetails,
        [name]: value,
      },
    }));
  };

  setNewAddress = () => {
        const header = {
      token: localStorage.getItem("auhtToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAddressCallId = requestMessage.messageId;
    const formData = new FormData();
    formData.append("name", this.state.addAddressFormDetails.name);
    formData.append("address", this.state.addAddressFormDetails.address);
    formData.append("city", this.state.addAddressFormDetails.city);
    formData.append("zipcode", this.state.addAddressFormDetails.zip_code);
    formData.append("country", this.state.addAddressFormDetails.country);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/planner_dashboard_home/create_billing_address`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setUpdateAddress = () => {
    const header = {
      token: localStorage.getItem("auhtToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editAddressCallId = requestMessage.messageId;
    const formData = new FormData();
    formData.append("name", this.state.addAddressFormDetails.name);
    formData.append("address", this.state.addAddressFormDetails.address);
    formData.append("city", this.state.addAddressFormDetails.city);
    formData.append("zipcode", this.state.addAddressFormDetails.zip_code);
    formData.append("country", this.state.addAddressFormDetails.country);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/planner_dashboard_home/edit_billing_address?id=${this.state.addAddressFormDetails.accountId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToEditAccountPage = () => {
    this.props.navigation.navigate("EditAccountInfo")
  }
  goToEditPage=()=>{
    this.props.navigation.navigate("EditPaymentDetails") 
  }
  goToPaymentPage=()=>{
    this.props.navigation.navigate("Payment") 
  }
  //piechart
  getPieChartData = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: localStorage.getItem("auhtToken")
    };
    this.getPieChartDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_dashboard/planner_dashboard_payments/service_overview'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //getBillingAddress
  getBillingAddressData = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: localStorage.getItem("auhtToken")
    };
    this.getBillingAddressCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_dashboard/planner_dashboard_home/show_billing_address'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //Add account details
  setNewAccountDetails = () => {
    const header = {
      "Content-Type": "application/json",  
       token: localStorage.getItem("auhtToken")
   };
  const body={
    data:{
        beneficiary_name: this.state.addAccountDetails.beneficiary_name,
        address:  this.state.addAccountDetails.address,
        bank_name:"",
        bank_number: this.state.addAccountDetails.bank_number, 
        iban_number:  this.state.addAccountDetails.iban_number,
    }

 }
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.addAccountCallId = requestMessage.messageId;


requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_dashboard/planner_dashboard_payments`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
requestMessage.addData
  (getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(body)
  );
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  "POST"
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  }
getAccountDetails = () => {
    const header = {
      "Content-Type": "application/json",  
       token: localStorage.getItem("auhtToken")
   };
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getAccountCallId = requestMessage.messageId;


requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_dashboard/planner_dashboard_payments`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  "GET"
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  editAccountDetails = ()=>{
    const header = {
      "Content-Type": "application/json",  
       token: localStorage.getItem("auhtToken")
   };
  const body={
    data:{
        beneficiary_name: this.state.addAccountDetails.beneficiary_name,
        address:  this.state.addAccountDetails.address,
        bank_name:"",
        bank_number: this.state.addAccountDetails.bank_number, 
        iban_number:  this.state.addAccountDetails.iban_number,
    }

 }
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.editAccountCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_dashboard/planner_dashboard_payments/${this.state.addAccountDetails.wealth_planner_id}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
requestMessage.addData
  (getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(body)
  );
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  "PUT"
);

runEngine.sendMessage(requestMessage.id, requestMessage);
}
handleGetTransactionResponse(response:any,apiRequestCallId:string){
  if(apiRequestCallId === this.getTransactionCallId){
      this.setState({
        totalLists: response.count,
        paymentTransaction:response.data,
      })  
  }
}
getTransactionDetails = (page:number=1,filters?:TFilter) => {
  let filterstring = ""
  if(filters){
    filterstring =`?date=${filters.date}&status=${filters.status}&page=${page}`
  }
  
  const header = {
    "Content-Type": "application/json",  
     token: localStorage.getItem("auhtToken")
 };
  const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getTransactionCallId = requestMessage.messageId;

  requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_proposal_generation/service_proposals/get_clients_transactions${filterstring}`
  );

  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
formatCardNumber = (cardNumber:string) => {
  const digitsOnly = cardNumber.replace(/\D/g, '');
  if (!digitsOnly) {
    return '';
  }
  const maskedLength = Math.max(digitsOnly.length - 4, 0);
  const maskedNumber = 'X'.repeat(maskedLength) + digitsOnly.slice(-4);
  const formattedNumber =  maskedNumber.match(/.{1,4}/g);
  
  return  formattedNumber?formattedNumber.join(' '):"";
}
navigateRoute2 = (route: string) => {
  const msg = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
  msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
  );
  this.send(msg)
}
  functioncheck = async () => {
    let authToken = await storage.get('authToken');
    let auhtToken = await storage.get('auhtToken');
    if (!authToken) {
      this.navigateRoute2("LoginPlanner")
    } else if (!auhtToken) {
      this.navigateRoute2("EmailAccountLoginBlock")
    }
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      isToggledForViewAssets: !prevState.isToggledForViewAssets,
    }));
  };


  // Customizable Area End

}
