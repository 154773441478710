// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import toast from "react-hot-toast";
import storage from "framework/src/StorageProvider";

export const configJSON = require("./config");

interface Address {
    city?: string | null;
    country?: string | null;
    line1?: string | null;
    line2?: string | null;
    postal_code?: string | null;
    state?: string | null;
    account_id?: number | string | null;
    address?: string | null;
    name?: string | null;
    zipcode?: string | null;
}

export interface DataMedia {
    url: string;
    blob_id: number;
    filename: string;
    id?: string;
}

export interface PaymentResponse {
    id?: string;
    type?: string;
    attributes?: Address;
    data?: {
        id: string
    };
    errors?: Object
}

export interface ApiResponse {
    id?: string;
    type?: string;
    attributes?: Address;
    data?: Array<DataMedia>;
    errors?: Object
}

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    token: string;
    paymentMethod: string,
    address: any;
    addAddressFormDetails: { name: string, address: string, city: string, zip_code: any, country: string },
    paymentMethodAddDetails: { cardName: any, expiry: any, cardNumber: any, cvv: string | any },
    selectedPaymentMethod: string;
    paymentMethods: string[];
    openSuccessAddress: boolean,
    card_id: string | number | any;
    saveCardForFuture: boolean;
    plannerId: string | number,
    countryName: string | any,
    countries: string[],
    allCountriesLocation: string[] | any[];
    countryPage: any,
    apiCallingStage: boolean;
    serviceDate: any;
    isButtonVisibile: boolean;
    sub_total: string,
    currency:string;
    saveCvvInfo: string;
    languagesLogin: string;
    selectedCardDetails: {
        card_name: string,
        card_number: number | null,
        expiry: string
    }

}

interface SS { }

export default class ServicePaymentController extends BlockComponent<
    Props,
    S,
    SS
> {
    editAddressCallId: string = "";
    addAddressCallId: string = "";
    getAddressCallId: string = "";
    addPaymentMethodCallId: string = "";
    addPaymentMethodFromEditCallId: string = "";
    getCardsCallId: string = "";
    setServiceMethodCallId: string = "";
    getCountryCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
        ];

        this.state = {
            serviceDate: {},
            token: localStorage.getItem('token') as string,
            paymentMethod: '',
            addAddressFormDetails: { name: '', address: '', city: '', zip_code: '', country: '' },
            address: '',
            countryName: '',
            paymentMethodAddDetails: { cardName: '', expiry: '', cardNumber: '', cvv: '' },
            selectedPaymentMethod: '',
            paymentMethods: [],
            openSuccessAddress: false,
            card_id: '',
            saveCardForFuture: true,
            plannerId: 132,
            countries: [],
            allCountriesLocation: [],
            countryPage: 0,
            apiCallingStage: true,
            isButtonVisibile: false,
            sub_total: '',
            currency:'',
            saveCvvInfo: '',
            languagesLogin: localStorage.getItem('language') == "Arabic" ? "rtl" : "ltr",
            selectedCardDetails: {
                card_name: "",
                card_number: null,
                expiry: ""
            }

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        let sub_total = await storage.get('sub_total')
        let currency = await storage.get('currency')
        const { location }: any = this.props;

        this.setState({
            serviceDate: location?.state,
            sub_total: sub_total,
            currency:currency,
        }, () => {
            this.getAddress();
            this.getCards();
            this.getCountriesForAddress();
        });

    }

    handleAddPaymentMethod = (responseJson: PaymentResponse) => {
        this.setState({ card_id: responseJson.data?.id }, () => {
            this.getCards();
            this.handleSetService();
        });
    };

    successCallBack = ((responseJson: PaymentResponse, apiRequestCallId: Object) => {
        if (!responseJson || responseJson.errors) return;


        switch (apiRequestCallId) {
            case this.addPaymentMethodCallId:
                this.handleAddPaymentMethod(responseJson);
                break;

            case this.getAddressCallId:
                this.setState({ address: responseJson.data });
                break;

            case this.addAddressCallId:
            case this.editAddressCallId:
                this.handleAddressResponse(responseJson);
                break;

            case this.setServiceMethodCallId:
                this.goToSuccessPage();
                break;

            case this.getCardsCallId:
                this.handleGetCards(responseJson);
                break;

            case this.getCountryCallId:
                this.handleGetCountries(responseJson);
                break;

            default:
                break;
        }
    });

    handleAddressResponse = (responseJson: any) => {
        if (responseJson.data) {
            this.setState({ paymentMethod: "apple_pay", openSuccessAddress: !this.state.openSuccessAddress });
            this.getAddress();
        }
    };

    handleGetCards = (responseJson: any) => {
        if (responseJson.data) {
            const paymentMethods: string[] = responseJson.data as string[];
            this.setState({ paymentMethods });
        }
    };

    callCountry = async () => {
        if (this.state.apiCallingStage) {
            this.setState({ apiCallingStage: false }, () => {
                this.getCountriesForAddress();
            })
        }
    }

    handleGetCountries = (responseJson: any) => {
        if (Array.isArray(responseJson) && responseJson.length > 1) {
            const countries = responseJson.map((value: any) => ({ value: value.id, label: value.attributes.name }));
            this.setState((prevState) => ({
                countries: [...prevState.countries, ...responseJson],
                allCountriesLocation: [...prevState.allCountriesLocation, ...responseJson],
                countryName: [...prevState.countryName, ...countries],
            }), () => {
                this.setState({ apiCallingStage: true });
            });

            this.callCountry();
        }
    };

    errorCallBack = (error: string) => {
        toast.error(this.getStringService('CardTaken'))
    }

    async checkboxHandle(value: string) {
        const { paymentMethodAddDetails } = this.state;
        this.setState({ paymentMethod: value });
        if (value === 'apple_pay') {
            this.setState({ isButtonVisibile: true })
        }
        else if (value === 'credit_debit_card') {
            if (
                paymentMethodAddDetails.cardName &&
                paymentMethodAddDetails.cardNumber &&
                paymentMethodAddDetails.expiry &&
                paymentMethodAddDetails.cvv.length == 3
            ) {
                this.setState({ isButtonVisibile: true })
            }
            else {
                this.setState({ isButtonVisibile: false })
            }
        }
        else {
            this.setState({ isButtonVisibile: true })
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived in");

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson) {
                if (responseJson.error || responseJson.errors) {
                    this.errorCallBack(responseJson.error || responseJson.errors)
                }
                else {
                    this.successCallBack(responseJson, apiRequestCallId)
                }
            }

            else {
                this.errorCallBack("Invalid card")
            }

        }
    }

    paymentMethodForDynamicCard = (values: any) => {
        this.checkboxHandle(`dynamic_card_${values.id}`)
        this.setState({ selectedPaymentMethod: values, card_id: values.id, selectedCardDetails:{
            card_name: values.attributes?.card_name,
            card_number: values.attributes?.card_number,
            expiry: values.attributes?.expires_on
        } })
    }

    editDetails = () => {
        this.setState({ addAddressFormDetails: { name: this.state.address?.attributes?.name, address: this.state.address?.attributes?.address, city: this.state?.address?.attributes?.city, zip_code: this.state.address?.attributes?.zipcode, country: this.state.address?.attributes?.country }, paymentMethod: 'edit_address' })
    }

    updateAddAddressDetails =
        async (name: any, value: any) => {
            if (name === 'name' || name === 'city') {
                const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, '');
                this.setState((prevState) => {
                    return { addAddressFormDetails: { ...prevState.addAddressFormDetails, [name]: sanitizedValue }, };
                });
            }
            else if (name === 'zip_code') {
                const sanitizedValue = value.replace(/\D/g, '');
                this.setState((prevState) => {
                    return { addAddressFormDetails: { ...prevState.addAddressFormDetails, [name]: sanitizedValue }, };
                });
            }
            else if (name == 'country') {
                this.setState((prevState) => {
                    return { addAddressFormDetails: { ...prevState.addAddressFormDetails, [name]: value.label, }, };
                });
            }
            else {
                this.setState((prevState) => {
                    return { addAddressFormDetails: { ...prevState.addAddressFormDetails, [name]: value, }, };
                });
            }
        };

    getCountriesForAddress = async () => {

        const requestMessageForCountries = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCountryCallId = requestMessageForCountries.messageId;

        requestMessageForCountries.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/get_countries?page=${this.state.countryPage + 1}`
        );
        this.setState({ countryPage: this.state.countryPage + 1 })

        requestMessageForCountries.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessageForCountries.id, requestMessageForCountries);
    }

    editAddress = () => {
        const header = { token: localStorage.getItem('token') };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.editAddressCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_stripe_integration/subscriptions/edit_billing_address?name=${this.state.addAddressFormDetails.name}&address=${this.state.addAddressFormDetails.address}&city=${this.state.addAddressFormDetails.city}&zipcode=${this.state.addAddressFormDetails.zip_code}&country=${this.state.addAddressFormDetails.country}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        runEngine.sendMessage(
            requestMessage.id, requestMessage
        );
    }

    goToSuccessPage = () => {

        const message = new Message(getName(MessageEnum.NavigationMessage));

        message.addData(getName(MessageEnum.NavigationTargetMessage), "ServicePaymentSuccess");

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message)
    }
    handleStoreCvvInfo = (event: any) => {
        this.setState({ saveCvvInfo: event.target.value })
      }


    handleIsProcessPaymentVisible = () => {
        const { paymentMethod, paymentMethodAddDetails } = this.state
        if (paymentMethod === "apple_pay") {
    
          return true;
        } else if (paymentMethod !== 'credit_debit_card' 
        && this.state.saveCvvInfo.length === 3
    ) {
          return true;
        }
        else if (
          paymentMethodAddDetails.cardName &&
          paymentMethodAddDetails.cardNumber &&
          paymentMethodAddDetails.expiry &&
          paymentMethodAddDetails.cvv.length == 3
        ) {
          return true
        } else {
    
          return false;
    
        }
      }


    updateAddCardDetails = async (name: any, value: any) => {
        if (name === 'cardName') {
            value = value.replace(/[^a-zA-Z\s]/g, '');;
        }
        if (
            name === "expiry"
            &&
            value.length === 2
            && value > 12) {
            return;
        }
        if (
            name === "expiry"
            && value.length > 5
        ) {
            return;
        }
        if (name === "cvv"
            && value.length > 3
        ) {
            return;
        }
        if (
            name === "cardNumber"
            && value.length > 19
        ) {
            return;
        }
        if (name === 'cardNumber') {
            value = value.replace(/\s/g, '');
        }
        this.setState((prevState) => {
            let updatedValue = value;
            if (name === "cardNumber") {
                updatedValue = updatedValue.replace(/(.{4})/g, '$1 ').trim();
            }
            if (name === "expiry" && value.length === 2) {
                updatedValue += "/";
            }
            return {
                paymentMethodAddDetails: { ...prevState.paymentMethodAddDetails, [name]: updatedValue, },
            };
        }, () => {
            this.checkboxHandle('credit_debit_card');
        });
    };

    getStringService = (key: string) => {
        let languageLogin = localStorage.getItem("language") || "English"
        return configJSON.languageListService[languageLogin][key]
    }

    handleSetService = () => {
        const header = {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json",
        };

        const requestMessageForCard = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const plannerId: any = localStorage.getItem("plannerId")
        this.setServiceMethodCallId = requestMessageForCard.messageId;
        let formBody = {
            "planner_id": plannerId,
            "card_id": this.state.card_id,
            "service": this.state.serviceDate?.services || [{
                "proposal_template_id": 57,
                "service_id": 8
            }]
        }

        requestMessageForCard.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_proposal_generation/user_service_selections`
        );

        requestMessageForCard.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageForCard.addData
            (getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(formBody)
            );
        requestMessageForCard.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessageForCard.id, requestMessageForCard);
    }

    handleCardToken = (cardToken: any) => {
          const header = {
            token: localStorage.getItem('token'),
            "Content-Type": 'application/json',
          };
          const requestMessageForCard = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
    
          this.addPaymentMethodCallId = requestMessageForCard.messageId;
    
          requestMessageForCard.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_stripe_integration/payment_methods`
          );
    
          requestMessageForCard.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessageForCard.addData
            (getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(cardToken)
            );
          requestMessageForCard.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
          );
    
          runEngine.sendMessage(requestMessageForCard.id, requestMessageForCard);
    };

    paymentByCard = () => {
        const header = { token: localStorage.getItem('token') };
        const requestMessageForCard = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.addPaymentMethodCallId =
            requestMessageForCard.messageId;
        const formData = new FormData();
        let newValue = this.state.paymentMethodAddDetails.cardNumber.replace(/\s/g, '')

        formData.append("cvv", this.state.paymentMethodAddDetails.cvv);
        formData.append("expires_on", this.state.paymentMethodAddDetails.expiry);
        formData.append("card_number", newValue);
        formData.append("card_name", this.state.paymentMethodAddDetails.cardName);

        requestMessageForCard.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_stripe_integration/payment_methods`);

        requestMessageForCard.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessageForCard.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        requestMessageForCard.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(
            requestMessageForCard.id, requestMessageForCard
        );
    }

    getCards = async () => {
        const header = {
            token: localStorage.getItem('token')
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCardsCallId = requestMessage.messageId;

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_stripe_integration/payment_methods`
        );

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
        );

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
        );

        runEngine.sendMessage(
            requestMessage.id, requestMessage
        );
    }

    getAddress = async () => {
        const header = {
            token: localStorage.getItem('token')
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAddressCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_stripe_integration/subscriptions/show_billing_address`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
        );

        runEngine.sendMessage(
            requestMessage.id, requestMessage
        );
    }

    completeServicePayment = () => {
        if (this.state.card_id) {
                this.handleSetService()
            }
    }


    setNewAddress = () => {
        if (!this.state.addAddressFormDetails.name.trim().length || !this.state.addAddressFormDetails.address.trim().length || !this.state.addAddressFormDetails.city.trim().length || !this.state.addAddressFormDetails.zip_code.trim().length || !this.state.addAddressFormDetails.country.length) {
            toast.dismiss()
            toast.error(this.getStringService('addressValidation'))
            return
        }

        let nameRegex = new RegExp(/[^a-zA-Z\s]/g, '')
        let newZipRegex=new RegExp(/\D/g);
    
        let newValueUpdated = nameRegex.test(this.state.addAddressFormDetails.name)
        let newZipValueUpdated = newZipRegex.test(this.state.addAddressFormDetails.zip_code)
    
    
        if (newValueUpdated === true ) {
          toast.error(this.getStringService('NameValidation'))
        
        }else if(newZipValueUpdated === true ){
          toast.error(this.getStringService('ZipLengthValidation'))
    
        }
        else{
        const editHeader = {
          token: localStorage.getItem('token')
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.addAddressCallId = requestMessage.messageId;
        const formData = new FormData();
        formData.append("name", this.state.addAddressFormDetails.name.replace(/\s+/g, ' ').trim());
        formData.append("address", this.state.addAddressFormDetails.address.replace(/\s+/g, ' ').trim());
        formData.append("city", this.state.addAddressFormDetails.city.replace(/\s+/g, ' ').trim());
        formData.append("zipcode", this.state.addAddressFormDetails.zip_code);
        formData.append("country", this.state.addAddressFormDetails.country);
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_stripe_integration/subscriptions/billing_address`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(editHeader)
        );
        requestMessage.addData
          (getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);}
      }


    updateSaveCardState = () => {
        if (this.state.saveCardForFuture) {
            this.setState({ saveCardForFuture: false })
            toast.error(this.getStringService('CardNotSaved'));
        }
        else {
            this.setState({ saveCardForFuture: true })
            toast.success(this.getStringService('CardSaveSuccess'));
        }
    }

    dialogCashModel = () => {
        this.setState({ openSuccessAddress: !this.state.openSuccessAddress })
    }

    handleNavigateAfterPayment = () => {
        document.dispatchEvent(new CustomEvent("estatePlanningUpdated"));
        const message = new Message(getName(MessageEnum.NavigationMessage));

        message.addData(getName(MessageEnum.NavigationTargetMessage), "EstatePlanning");

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message)
    }

}

// Customizable Area End
