import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from 'framework/src/Utilities';
import toast from "react-hot-toast";

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  hader_dropdown: any;
  selectCurrency: { value: string, label: string },
  selectCurrency2: { value: string, label: string },
  currencyList: any,
  logoImg1AddCash: string
  dataValue: Object
  dataValueNew: Object;
  isLoading: boolean;
  selectedPage: number;
  selectedMonth: string;
  selectedYear: string;
  transactionsData: any;
  transactionDetails: boolean;
  transactionId: any;
  singleTransactionDetails: any;
  singleTransactionDetailsDate: any;
  dialogCss: any;
  deleteModel: boolean;
  categoriesList:any;
  selectedCategoryId:any;
}

interface SS {
  id: any;
}

export default class TransactionAndSpendingDetailsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCurrencyApiCallId: any;
  setCurrencyApiCallId: any;
  getAllRecentTransactionsApiCallId: any;
  getTransactionDetailsCallId: any;
  deleteTransactionCallId: any;
  updateSubCategoryOfTransactionCallId: any;
  categoriesCallId: any;
  lastElementRef: any;
  observer: any = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      hader_dropdown: [],
      selectCurrency: { value: '', label: '' },
      selectCurrency2: { value: '', label: '' },
      currencyList: [],
      logoImg1AddCash: "",
      dataValue: {
        display: "flex", flexDirection: 'row-reverse', justifyContent: "end"
      },
      dataValueNew: {
        display: "flex", flexDirection: 'row', justifyContent: "end"
      },
      isLoading: false,
      selectedPage: 1,
      selectedMonth: '',
      selectedYear: '',
      transactionsData: [],
      transactionDetails: false,
      transactionId: '',
      singleTransactionDetails: '',
      singleTransactionDetailsDate: '',
      dialogCss: {
        width: 'max-content',
        backgroundColor: '#3c3c51',
        borderRadius: '12px',
        border: '1px solid #57575d',
        padding: '19px 23px',
      },
      deleteModel: false,
      selectedCategoryId: '',
      categoriesList:[],
    };
    this.lastElementRef = React.createRef();

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  getAllCurrencyList = () => {
    const authToken = localStorage.getItem("token")
    const header = { "token": authToken };
    let urlendpoint = configJSON.Loginsubmittoken;
    const bodyData = apiCall({
      header: header,
      httpBody: {},
      url: urlendpoint,
      httpMethod: configJSON.exampleAPiMethodtoken,
    });

    this.getCurrencyApiCallId = bodyData.messageId;
    runEngine.sendMessage(bodyData.id, bodyData);
  }
  async componentDidMount() {
    const language = await getStorageData('language');
    const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
    this.setState({ logoImg1AddCash: logoImg1AddCash });
    this.functionTokenCheck()
    this.getAllCurrencyList();
    this.getCategories();
    this.createObserver();
  }

  createObserver = () => {
    this.observer = new IntersectionObserver(
      this.observerCallBack,
      { threshold: 1.0 }
    );

    if (this.lastElementRef.current) {
      this.observer.observe(this.lastElementRef.current);
    }
  };

  observerCallBack = (entries: any) => {
    const entry = entries[0];
    const { transactionsData, selectedPage } = this.state
    const totalData = transactionsData.pages;
    const hasMore = totalData >= selectedPage
    if (entry.isIntersecting && hasMore) {
      this.allTransactions()

    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCurrencyApiCallId !== null &&
      this.getCurrencyApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.list) {
        const userSelectedCurrncy = apiResponse.list?.find((value: any) => {
          return value?.id == apiResponse?.selection
        })
        this.setState({
          selectedPage: 1,
          transactionsData: [],
          selectCurrency2: { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}` },
          currencyList: apiResponse,
          selectCurrency: { value: userSelectedCurrncy.currency_type, label: this.state.logoImg1AddCash === "rtl" ? `${userSelectedCurrncy.currency_type}${userSelectedCurrncy.symbol}` : `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` },
          hader_dropdown: apiResponse?.list?.map((value: any) => (
            { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
          ))
        }, () => this.getSearchParams());
      }
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.setCurrencyApiCallId !== null &&
      this.setCurrencyApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.meta.currency) {
        this.getAllCurrencyList()
      }
    }
    this.apiReciveFunctions(message)
    // Customizable Area End
  }

  apiReciveFunctions = (message: any) => {
    let responseJson;
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllRecentTransactionsApiCallId !== null &&
      this.getAllRecentTransactionsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let allTransactionApiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState((prev) => {
        allTransactionApiResponse.transaction.data = [...prev.transactionsData?.transaction?.data || [], ...allTransactionApiResponse.transaction.data]
        return {
          transactionsData: allTransactionApiResponse,
          isLoading: false,
          selectedPage: prev.selectedPage + 1
        }
      })
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTransactionDetailsCallId != null &&
      this.getTransactionDetailsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({ singleTransactionDetails: responseJson, transactionDetails: true,selectedCategoryId:responseJson?.sub_category?.id })
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteTransactionCallId != null &&
      this.deleteTransactionCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
       responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        if(responseJson.message){
          this.setState({transactionsData:[]})
          this.onCancel()
          this.setState({transactionDetails:false})
          toast.success(this.getStringTransactions('DeleteSuccess'))
          this.allTransactions()
        }
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateSubCategoryOfTransactionCallId != null &&
      this.updateSubCategoryOfTransactionCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
       responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        toast.success(responseJson.message)
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.categoriesCallId != null &&
      this.categoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
       responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.setState({categoriesList:responseJson.data})
      }
    }

  }

  getFloorAppend(floorNumber: number) {
    if (floorNumber % 100 === 11 || floorNumber % 100 === 12 || floorNumber % 100 === 13) {
      return "th";
    }
  }

  setCurrencyApi = (currencyId: string) => {
    const authToken = localStorage.getItem("token")
    const header = { "token": authToken };
    const formData = new FormData();
    formData.append("currency_id", currencyId);
    let userDetailurl = configJSON.Loginsubmittokenselect;
    const bodyData = apiCall({
      header: header,
      httpBody: formData,
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethodtokenselect,
    });
    this.setCurrencyApiCallId = bodyData.messageId;
    runEngine.sendMessage(bodyData.id, bodyData);
  }


  allTransactions = async () => {
    if (this.state.isLoading) {
      return
    }
    this.setState({
      isLoading: true
    })
    this.functionTokenCheck()
    const authToken = localStorage.getItem("token")
    const header = { "token": authToken };
    const bodyData = apiCall({
      header: header,
      httpBody: {},
      url: `${configJSON.transactionApiEndPoint}?month=${this.state.selectedMonth}&year=${this.state.selectedYear}&page=${this.state.selectedPage}`,
      httpMethod: configJSON.reacttransactionsget,
    });
    this.getAllRecentTransactionsApiCallId = bodyData.messageId;
    runEngine.sendMessage(bodyData.id, bodyData);
  }

  transactionDetailAPI = async (id: any) => {
    const language = await getStorageData('language');
    const authToken = localStorage.getItem("token")

    this.setState({ transactionId: id })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTransactionDetailsCallId = requestMessage.messageId;
    const header = {
      token: authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/bank_account_transactions/${id}?language=${language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateSubCategoryOfTransaction = async () => {
    const authToken = localStorage.getItem("token")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateSubCategoryOfTransactionCallId = requestMessage.messageId;
    const header = {
      token: authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/bank_account_transactions/${this.state.transactionId}?sub_category_id=${this.state.selectedCategoryId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  deleteTransaction = async () => {
    const authToken = localStorage.getItem("token")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTransactionCallId = requestMessage.messageId;
    const header = {
      token: authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/bank_account_transactions/${this.state.transactionId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCategories = async ()=>{
    const authToken = localStorage.getItem("token")
    const requestMessageData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: authToken
    };
    this.categoriesCallId = requestMessageData.messageId;
    requestMessageData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/sub_categories/?name=spendingcategories"
    );
    requestMessageData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessageData.id, requestMessageData);
  }
  getSearchParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const month = urlParams.get('month');
    const year = urlParams.get('year');
    if (month && year) {
      this.setState({
        selectedMonth: month,
        selectedYear: year
      })
      this.allTransactions()
    }
  }

  setCurrency = (value: { value: string, label: string }) => {
    const currencyId = this.state.currencyList?.list?.find((newValue: any) => {
      return newValue?.currency_type == value?.value
    })
    this.setCurrencyApi(currencyId?.id)
  }


  navigateToLogin = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg)
  }
  functionTokenCheck = async () => {
    let authToken = localStorage.getItem("token")
    if (!authToken) {
      window.localStorage.clear();
      this.navigateToLogin()
    }
  }

  dialogModel = () => {
    this.setState({ deleteModel: !this.state.deleteModel })
  }
  onCancel = () => {
    this.setState({ deleteModel: !this.state.deleteModel })
  }
  getStringTransactions = (key: string) => {
    let languageLogin = localStorage.getItem("language") || "English"
    return configJSON.languageListForecasting[languageLogin][key]
  }

  handleCategoryChange = (event:any) => {
    // istanbul ignore next
    this.setState({selectedCategoryId:event.target.value})
  };

  handleGoPreviousState = () =>{
    this.setState({ transactionDetails: false })
  }
  // Customizable Area End
}
