import React, { Component } from 'react';
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  ListItemText,
  Collapse,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import storage from 'framework/src/StorageProvider';

// Define types for account and options
interface Account {
  id: number;
  bank_account_id: number;
  account_number: string;
  image: string;
  type: string;
}

interface Option {
  name: string;
  bank_id: number;
  accounts: Account[];
}

// Custom styles
const styles = (theme: Theme) =>
  createStyles({
    select: {
      width: '100% !important',
      backgroundColor: '#3C3C50',
      border: '1px solid #94989c',
      color: '#2CE2D5 !important',
      height: '54px !important',
      display: 'flex !important',
      alignItems: 'center !important',
      padding: '12px !important',
      borderRadius: '10px',
      '&:focus': {
        backgroundColor: '#3C3C50',
        borderRadius: '10px',
      },
    },
    icon: {
      color: '#2CE2D5 !important',
    },
    checkbox: {
      color: '#2CE2D5 !important',
      padding: '24px',
      '&$checked': {
        color: '#2CE2D5 !important',
      },
    },
    checked: {},
    menuPaper: {
      backgroundColor: '#3C3C50',
    },
    placeholder: {
      fontSize: '14px !important',
      color: '#aaa',
    },
    accountImage: {
      width: '24px',
      height: '24px',
      marginRight: '8px',
    },
  });

// Modify the Props interface to accept options and handleApiCall
interface Props {
  classes: {
    select: string;
    icon: string;
    checkbox: string;
    checked: string;
    menuPaper: string;
    placeholder: string;
    accountImage: string;
  };
  options: Option[]; 
  handleApiCall: (selectedBankIds: number[]) => void;
}

interface State {
  selectedItems: number[];
  isOpen: { [key: string]: boolean };
  accountMap: { [key: number]: string };
}

class ParentChildDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedItems: [],
      isOpen: {},
      accountMap: {},
    };
  }

  async componentDidMount() {
    const accountMap: { [key: number]: string } = {};
    this.props.options.forEach((option: Option) => {
      option.accounts.forEach((account: Account) => {
        accountMap[account.id] = `${account.account_number}`;
      });
    });
    
    // Load selected items from localStorage
    const storedSelectedItems = await storage.get("bank_account_ids");
    const selectedItems = storedSelectedItems ? JSON.parse(storedSelectedItems) : [];

    // Set the first bank's collapse state to open by default
    const isOpen = this.props.options.length > 0 ? { [`${this.props.options[0].bank_id}`]: true } : {};

    this.setState({ accountMap, selectedItems,isOpen });
  }

  toggleCollapse = (parentId: string) => {
    this.setState((prevState) => {
      const newIsOpen: any = {};
      Object.keys(prevState.isOpen).forEach((key) => {
        newIsOpen[key] = false;
      });
      newIsOpen[parentId] = !prevState.isOpen[parentId];
      return { isOpen: newIsOpen };
    });
  };

  isParentSelected = (childrenIds: number[]) => {
    const { selectedItems } = this.state;
    return childrenIds.every((id) => selectedItems.includes(id));
  };

  handleSelectParent = (parentId: string, accounts: Account[]) => {
    const { selectedItems } = this.state;
    const accountIds = accounts.map((account) => account.id);
    let updatedSelection: number[] = [...new Set(selectedItems)];

    if (this.isParentSelected(accountIds)) {
      updatedSelection = updatedSelection.filter((id) => !accountIds.includes(id));
    } else {
      accountIds.forEach((id) => {
        if (!updatedSelection.includes(id)) {
          updatedSelection.push(id);
        }
      });
    }

    this.setState({ selectedItems: updatedSelection }, this.triggerApiCall);
  };

  handleSelectChild = (childId: number) => {
    const { selectedItems } = this.state;
    let updatedSelection: number[] = [...new Set(selectedItems)];

    if (updatedSelection.includes(childId)) {
      updatedSelection = updatedSelection.filter((id) => id !== childId);
    } else {
      updatedSelection.push(childId);
    }

    this.setState({ selectedItems: updatedSelection }, this.triggerApiCall);
  };

  triggerApiCall = async () => {
    const { selectedItems } = this.state;
    await storage.set("bank_account_ids", JSON.stringify(selectedItems))
    this.props.handleApiCall(selectedItems);
  };

  render() {
    const { selectedItems, isOpen, accountMap } = this.state;
    const { classes, options } = this.props;
  
    // Create a unique list of options by bank_id
    const uniqueOptions = Array.from(
      new Map(options.map(option => [option.bank_id, option])).values()
    );
  
    return (
      <FormControl fullWidth>
        <Select
          multiple
          displayEmpty
          value={selectedItems}
          renderValue={(selected) => {
            if ((selected as number[]).length === 0) {
              return <span className={classes.placeholder}>Select bank</span>;
            }
            return (selected as number[]).map((id) => accountMap[id]).join(', ');
          }}
          classes={{
            root: classes.select,
            icon: classes.icon,
          }}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            PaperProps: {
              style: {
                maxHeight: '400px',
                overflowY: 'auto',
              },
            },
          }}
        >
          {uniqueOptions.length === 0 ? (
            <MenuItem style={{ color: '#ffffff' }}>
              <ListItemText primary="No Bank Data" />
            </MenuItem>
          ) : (
            uniqueOptions.map((option: Option, key: any) => (
              <div key={`${option.bank_id}-${key}`} style={{ backgroundColor:'#395D6B', fontFamily:`'Poppins', sans-serif` }}>
                <MenuItem style={{ color: '#ffffff', height:'56px' }}>
                  <Checkbox
                    checked={this.isParentSelected(option.accounts.map((account: Account) => account.id))}
                    onChange={() => this.handleSelectParent(`${option.bank_id}`, option.accounts)}
                    classes={{ root: classes.checkbox, checked: classes.checked }}
                  />
                  <img src={option.accounts[0]?.image} alt={option.name} className={classes.accountImage} />
                  <ListItemText primary={option.name} />
                  <IconButton onClick={() => this.toggleCollapse(`${option.bank_id}`)} className={classes.icon}>
                    {isOpen[`${option.bank_id}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </MenuItem>

                <Collapse in={isOpen[`${option.bank_id}`]} timeout="auto" unmountOnExit>
                  {option.accounts.length > 0 ? (
                    option.accounts.map((account: Account) => (
                      <MenuItem key={account.id} style={{ paddingLeft: 32, color: '#ffffff' }}>
                        <Checkbox
                          checked={selectedItems.includes(account.id)}
                          onChange={() => this.handleSelectChild(account.id)}
                          classes={{ root: classes.checkbox, checked: classes.checked }}
                        />
                        <img src={account.image} alt={account.account_number} className={classes.accountImage} />
                        <ListItemText primary={account.account_number} />
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem style={{ paddingLeft: 32, color: '#ffffff', minHeight: '48px', display: 'flex', alignItems: 'center' }}>
                      <ListItemText primary="No Bank Data" />
                    </MenuItem>
                  )}
                </Collapse>
              </div>
            ))
          )}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(ParentChildDropdown);