import React from "react"
// Customizable Area Start
import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import { Link, NavLink } from 'react-router-dom';
import { imageVuesax } from "./assets"
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import { down, up } from "../../dashboard/src/assets";
import { backIcon } from "../../forgot-password/src/assets";
import "./transaction.css"
// Customizable Area End
import TransactionAndSpendingDetailsPageController, { Props } from './TransactionAndSpendingDetailsPageController.web';
import { image_BackIcon, deleteIcon } from "../../visualanalytics/src/assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import { formatNumberWithCommasAndDecimals } from "../../../components/src/commonFn";

export default class TransactionAndSpendingDetailsPage extends TransactionAndSpendingDetailsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    styleCss = () => {
        return this.state.logoImg1AddCash === "rtl" ? this.state.dataValue : this.state.dataValueNew
    }
    getArrowImage(amountOperand: string) {
        return amountOperand === '+' ? up : down;
    }
    image_BackIcon = () => {
        return this.state.logoImg1AddCash === "rtl" ? image_BackIcon : backIcon
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <div className="main-container" dir={this.state.logoImg1AddCash}>
                <Box>
                    <Box className="trendsoverflowY" >
                        <Box className="trendsfont">
                            {this.state.transactionDetails ? <img src={this.image_BackIcon()} alt="back-icon" data-test-id="back-transaction" className="back-icon" onClick={this.handleGoPreviousState} /> :
                                <NavLink to="/ForecastingandbudgetingTrends" className="back-icon-link">
                                    <img src={this.image_BackIcon()} alt="back-icon" className="back-icon" />
                                </NavLink>
                            }

                            <p className="trendsAfont">{this.getStringTransactions('AllTransactions')} </p>
                        </Box>
                        <Box className="addCashrightHeaderDropSetting">
                            <Box className="trendsicon">
                                <FormControl>
                                    <Select options={this.state.hader_dropdown}
                                        value={this.state.selectCurrency}
                                        onChange={(val: any) => { this.setCurrency(val) }}
                                        classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="selectMenu" />
                                </FormControl>
                                <Box style={{ paddingLeft: '20px' }} data-test-id="addCashDataSetting">
                                    <Link to="/Account-Detail">
                                        <img src={imageVuesax} className="trendsSettingimg" />
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {
                    this.state.transactionDetails ? <>

                        <Box className="details-container">
                            <Box className="table-format">
                                <img src={this.state.singleTransactionDetails.sub_category.image} className="transactionDetailImg" width={48} />
                                <img src={deleteIcon} data-test-id="delete-transaction" width={36} style={{ cursor: 'pointer' }} onClick={this.onCancel} />
                            </Box>
                            <Box className="detail-info-container">
                                <Box className="table-format">
                                    <span className="detail-title">{this.getStringTransactions('Status')}</span>
                                    <span className="detail-title-value">{this.state.singleTransactionDetails.status}</span>
                                </Box>
                                <Box className="table-format">
                                    <span className="detail-title">{this.getStringTransactions('Category')}</span>
                                    <div className="select-container">
                                        <select id="options" className="custom-select" value={this.state.selectedCategoryId}
                                            onChange={this.handleCategoryChange}>
                                            {
                                                this.state.categoriesList?.map((value: any, key: any) => (
                                                    <option value={value.attributes.id} key={key}>{value.attributes.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Box>
                                <Box className="table-format">
                                    <span className="detail-title"> {this.getStringTransactions('Source')}</span>
                                    <span className="detail-title-value">{this.state.singleTransactionDetails.source}</span>
                                </Box>
                            </Box>
                            <Box className="detail-info-container">
                                <Box className="table-format">
                                    <span className="detail-title">{this.getStringTransactions('CurrentAccount')}</span>
                                    <span className="detail-title-value">{this.state.singleTransactionDetails.current_account}</span>
                                </Box>
                                <Box className="table-format">
                                    <span className="detail-title"> {this.getStringTransactions('Amount')}</span>
                                    <p className={this.state.singleTransactionDetails.operand === "+" ? "amount-positive" : "amount-negative"}>
                                        {`${this.state.singleTransactionDetails.operand} ${formatNumberWithCommasAndDecimals(this.state.singleTransactionDetails.amount)} ${this.state.singleTransactionDetails.currency_symbol}`}
                                        <img
                                            className="arrow-icon"
                                            src={this.getArrowImage(this.state.singleTransactionDetails.operand)}
                                            alt={this.state.singleTransactionDetails.operand === '+' ? 'Up Arrow' : 'Down Arrow'}
                                            width={16}
                                            height={16}
                                        />
                                    </p>
                                </Box>
                            </Box>
                            <Box className="detail-info-container">
                                <Box className="table-format">
                                    <span className="detail-title">{this.getStringTransactions('Date')}</span>
                                    <span className="detail-title-value">{this.state.singleTransactionDetails.date}</span>
                                </Box>
                                <Box className="table-format">
                                    <span className="detail-title">{this.getStringTransactions('Time')}</span>
                                    <span className="detail-title-value">{this.state.singleTransactionDetails.time}</span>
                                </Box>
                            </Box>
                            <Box className="table-format">
                                <div></div>
                                <button className="save-btn-transDetails" data-test-id="save-btn" onClick={this.updateSubCategoryOfTransaction}> {this.getStringTransactions('Save')}</button>
                            </Box>
                        </Box>
                    </> : <>

                        <h3 className="month-info">{this.state.transactionsData.month}</h3>
                        <Box>
                            {
                                this.state.transactionsData?.transaction?.data?.map((item: any, key: any) => {
                                    const amountClass = item?.attributes?.amount_operand === "+" ? "amount-positive" : "amount-negative";
                                    return (
                                        <Box key={key} className="box-container">
                                            <Box className="box-start">
                                                <img src={item?.attributes?.sub_category?.image} width={48} height={48} alt={item?.attributes?.sub_category.name} />
                                                <p className="description" data-test-id="view-details-part" onClick={() => { this.transactionDetailAPI(item.attributes.id) }}>{item?.attributes?.description}</p>
                                                <p className="date">{item?.attributes?.transaction_date}</p>
                                            </Box>
                                            <Box className="box-end">
                                                <p className={amountClass} style={{justifyContent:'end'}}>

                                                    {`${item?.attributes?.amount_operand} ${formatNumberWithCommasAndDecimals(item?.attributes?.converted_amount)} ${item?.attributes?.user_currency}`}
                                                    <img
                                                        className="arrow-icon"
                                                        src={this.getArrowImage(item?.attributes?.amount_operand)}
                                                        alt={item?.attributes?.amount_operand === '+' ? 'Up Arrow' : 'Down Arrow'}
                                                        width={16}
                                                        height={16}
                                                    />
                                                </p>
                                                <p style={{justifyContent:'end'}} className="sub-category-name">{`${item?.attributes?.sub_category.name}`}</p>
                                            </Box>
                                        </Box>
                                    )
                                })

                            }
                            {this.state.isLoading && (
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '20px 0'
                                }}>
                                    <CircularProgress />
                                </Box>
                            )}
                        </Box>
                        <div
                            ref={this.lastElementRef}
                        >
                        </div>

                    </>
                }

                <Dialog
                    open={this.state.deleteModel}
                    keepMounted
                    data-test-id="dialogModel"
                    onClose={this.dialogModel}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{ style: this.state.dialogCss }}
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0px 65px', }}>
                        <span style={{
                            color: '#fff',
                            textAlign: 'center',
                            fontSize: '13px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                        }}>{this.getStringTransactions('DeleteNote')}</span><br /><span style={{
                            color: '#fff',
                            textAlign: 'center',
                            fontSize: '13px',
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            display: "flex",
                            justifyContent: "center"
                        }}>{this.getStringTransactions('TheTransaction')}</span></DialogTitle>
                    <DialogContent style={{
                        padding: '0px!important',
                        marginBottom: '0px',
                        paddingTop: '15px'
                    }}>
                        <DialogContentText id="alert-dialog-slide-description"
                            style={{
                                color: 'rgb(255, 255, 255) !important',
                                fontSize: '20px !important',
                                fontFamily: 'Poppins !important',
                                fontWeight: 500,
                                marginBottom: '0px',
                            }}>
                            <div className="modal-button-container">
                                <div className="modal-button modal-save" data-test-id='popup-cancle-click' onClick={this.onCancel}>
                                    {this.getStringTransactions('Cancel')}
                                </div>
                                <div className="modal-button modal-discard" data-test-id='popup-for-delete' onClick={this.deleteTransaction} >
                                    {this.getStringTransactions('Delete')}
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>


            </div>
        )
    }

}
// Customizable Area Start

// Customizable Area End
