import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from 'framework/src/Utilities';

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  hader_dropdown: any;
  selectCurrency: { value: string, label: string },
  selectCurrency2: { value: string, label: string },
  currencyList: any,
  reactTrans: any;
  lowamount: string;
  spendingCategory: any;
  selectedTransactionMonth: any
  transactionMonth: any;
  chartData: any;
  lineSeriesDatasUplod: any
  xaxisName: any;
  selectedTransactionyear: any
  transactionYear: any;
  selectedMonth: string;
  selectedYear: string;
  logoImg1AddCash: string
  dataValue: Object
  dataValueNew: Object
}

interface SS {
  id: any;
}

export default class ForecastingandbudgetingTrendsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUserPDFDataCallId: any;
  postUserSelectDataCallId: any;
  reactTransactionsApiCallId: any
  reactSpendingCategoryApiCallId: any
  spendingGraphDataCallId: any
  reactMonthsApiCallId: any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      hader_dropdown: [],
      selectCurrency: { value: '', label: '' },
      selectCurrency2: { value: '', label: '' },
      currencyList: [],
      reactTrans: [],
      lowamount: '-',
      spendingCategory: '',
      selectedTransactionMonth: 0,
      transactionMonth: '',
      lineSeriesDatasUplod: [],
      selectedTransactionyear: '',
      transactionYear: '',
      chartData: {
        series: [
          {
            name: 'Incomes',
            type: 'column',
            data: [],
            color: "#2CE2D5",
            states: {
              hover: undefined,
            },
          },
          {
            name: 'Expenses',
            type: 'column',
            data: [],
            color: "#814FF9",
            states: {
              hover: undefined,
            },
          },
          {
            name: 'Saving',
            type: 'line',
            data: [],
            color: "#FFFFFF",
            states: {
              hover: undefined,
            },
          },
        ],
      },
      xaxisName: [],
      selectedMonth: '',
      selectedYear: '',
      logoImg1AddCash: "",
      dataValue: {
        display: "flex", flexDirection: 'row-reverse', justifyContent: "end"
      },
      dataValueNew: {
        display: "flex", flexDirection: 'row', justifyContent: "end"
      },
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  convertToThousand = (amount: any) => {
    let convertedAmount = parseFloat(amount)
    let newAmount;
    if (convertedAmount >= 1000) {
      newAmount = (convertedAmount / 1000).toFixed(1) + 'k';
    }
    else {
      newAmount = convertedAmount;
    }
    return newAmount || 0;
  }

  getStringForcastingan = (key: string) => {
    let languageLogin = localStorage.getItem("language") || "English"
    return configJSON.languageListForecasting[languageLogin][key]
  }
  getCurrencyList = () => {
    const cashData = localStorage.getItem("token")
    const header = { "token": cashData };
    let userDetailurl = configJSON.Loginsubmittoken;
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethodtoken,
    });

    this.postUserPDFDataCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }
  async componentDidMount() {
    const language = await getStorageData('language');
    const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
    this.setState({ logoImg1AddCash: logoImg1AddCash });
    this.functionTokenCheck()
    this.apiTransactions()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserPDFDataCallId !== null &&
      this.postUserPDFDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let cashResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.reactTransactionsApi()
      this.reactMonthsApi()

      if (cashResponseJson.list) {
        const userSelectedCurrncy = cashResponseJson.list?.find((value: any) => {
          return value?.id == cashResponseJson?.selection
        })
        this.setState({
          currencyList: cashResponseJson,
          selectCurrency: { value: userSelectedCurrncy.currency_type, label: this.state.logoImg1AddCash === "rtl" ? `${userSelectedCurrncy.currency_type}${userSelectedCurrncy.symbol}` : `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}`  },
          selectCurrency2: { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}` },
        });
        this.setState({
          hader_dropdown: cashResponseJson?.list?.map((value: any) => (
            { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
          ))
        });
      }
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserSelectDataCallId !== null &&
      this.postUserSelectDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let cashResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (cashResponseJson.meta.currency) {
        this.getCurrencyList()
      }
    }
    this.callInitialFunction(message)
    // Customizable Area End
  }

  callInitialFunction=(message:any)=>{

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.reactTransactionsApiCallId !== null &&
      this.reactTransactionsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let reactTransactionsApiCallIdJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ reactTrans: reactTransactionsApiCallIdJson.data });
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.reactSpendingCategoryApiCallId !== null &&
      this.reactSpendingCategoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let reactSpendingCategoryApiCallIdJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ spendingCategory: reactSpendingCategoryApiCallIdJson.spending_category });
      this.reactTransactionsApi()
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.reactMonthsApiCallId !== null &&
      this.reactMonthsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let reactMonthsApiCallIdJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ transactionMonth: reactMonthsApiCallIdJson.Months, });
      const selectedDates = this.state.transactionMonth[this.state.selectedTransactionMonth];
      let [month, year] = selectedDates.split(' ');
      this.setState({ transactionYear: year, selectedMonth: month, selectedYear: year });
      if (this.state.transactionMonth) {
        this.spendingGraphicalData(year)
      }
      if (year) {
        this.reactSpendingCategoryApi(month, year)
      }
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.spendingGraphDataCallId !== null &&
      this.spendingGraphDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let reactMonthsApiCallIdJsonCharet = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const graphicalData = reactMonthsApiCallIdJsonCharet.graphicalData;
      const lineSeriesData = graphicalData.map((item: any) => item.transactions.total_incomes);
      const columnSeries1Data = graphicalData.map((item: any) => item.transactions.total_expenses);
      const columnSeries2Data = graphicalData.map((item: any) => item.transactions.total_savings);
      const xaxisName = graphicalData.map((item: any) => item.month);
      const updatedChartData = { ...this.state.chartData };

      updatedChartData.series[0].data = lineSeriesData.reverse();
      updatedChartData.series[1].data = columnSeries1Data.reverse();
      updatedChartData.series[2].data = columnSeries2Data.reverse();

      this.setState({ chartData: updatedChartData });
      this.setState({ xaxisName: xaxisName.reverse() });
    }
  }
  apiTransactions = () => {
    this.getCurrencyList();
  }

  setCurrency = (value: { value: string, label: string }) => {
    const currencyId = this.state.currencyList?.list?.find((newValue: any) => {
      return newValue?.currency_type == value?.value
    })
    this.setCurrencyPutapi(currencyId?.id)
  }

  setCurrencyPutapi = (currencyId: string) => {
    const cashData = localStorage.getItem("token")
    const header = { "token": cashData };
    const formData = new FormData();
    formData.append("currency_id", currencyId);
    let userDetailurl = configJSON.Loginsubmittokenselect;
    const postPdfData = apiCall({
      header: header,
      httpBody: formData,
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethodtokenselect,
    });
    this.postUserSelectDataCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }


  reactTransactionsApi = () => {
 this.functionTokenCheck()
    const cashData = localStorage.getItem("token")
    const header = { "token": cashData };
    const userDetailurl = this.state.logoImg1AddCash === "rtl" ? configJSON.reacttransactionsNew : configJSON.reacttransactions;
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: `bx_block_budgetingforecasting/graph_charts/recent_transactions?[date][month]=${this.state.selectedMonth}&[date][year]=${this.state.selectedYear}`,
      httpMethod: configJSON.reacttransactionsget,
    });
    this.reactTransactionsApiCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }

  reactSpendingCategoryApi = (month: any, year: any) => {
    const cashData = localStorage.getItem("token")
    const header = { "token": cashData };
    let userDetailurl = `bx_block_budgetingforecasting/graph_charts/spendings_in_chart?month=${month}&year=${year}`;
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: userDetailurl,
      httpMethod: configJSON.reactspendingsget,
    });
    this.reactSpendingCategoryApiCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }

  navigateToTransactions=()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Transactions");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { selectedyear:this.state.selectedYear,
      selectedmonth:this.state.selectedMonth,
      })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message)
      
  }

  navigateToSpendings=()=>{
    const message2 = new Message(getName(MessageEnum.NavigationMessage));
    message2.addData(getName(MessageEnum.NavigationTargetMessage), "spendingCategory");
    message2.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage2: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage2.addData(getName(MessageEnum.NavigationPayLoadMessage), { selectedyear:this.state.selectedYear,
      selectedmonth:this.state.selectedMonth,
      })
    message2.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage2);
    this.send(message2)
      
  }
  updateMonth = async (direction: any) => {
    if (direction == 'minus') {
      if (this.state.selectedTransactionMonth < this.state.transactionMonth.length) {
        const selectedDates = this.state.transactionMonth[this.state.selectedTransactionMonth + 1];
        let [month, year] = selectedDates.split(' ');
        this.setState({ selectedTransactionyear: year, selectedTransactionMonth: this.state.selectedTransactionMonth + 1, selectedMonth: month, selectedYear: year })
        if (year !== this.state.transactionYear) {
          this.setState({ transactionYear: year });
          this.spendingGraphicalData(year)
        }
        this.reactSpendingCategoryApi(month, year)
      }
    } else {
      if (this.state.selectedTransactionMonth > 0) {
        const selectedDates = this.state.transactionMonth[this.state.selectedTransactionMonth - 1];
        let [month, year] = selectedDates.split(' ');
        this.setState({ selectedTransactionyear: year, selectedTransactionMonth: this.state.selectedTransactionMonth - 1, selectedMonth: month, selectedYear: year })
        if (year !== this.state.transactionYear) {
          this.setState({ transactionYear: year });
          this.spendingGraphicalData(year)
        }
        this.reactSpendingCategoryApi(month, year)
      }
    }
  }

  spendingGraphicalData = (years: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const cashData = localStorage.getItem("token")
    const headers = {
      token: cashData
    };
    this.spendingGraphDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/bank_account_transactions/graphical_data?month=12&year=${years}&months_needed=12`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  reactMonthsApi = () => {
    const cashData = localStorage.getItem("token")
    const header = { "token": cashData };
    let userDetailurl = configJSON.reactMonths;
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: userDetailurl,
      httpMethod: configJSON.reactMonthsget,
    });
    this.reactMonthsApiCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }

  navigateToDetailsPage=()=>{
    this.props.navigation.history.push(`/TransactionAndSpendingDetailsPage?month=${this.state.selectedMonth}&year=${this.state.selectedYear}`)
  }

  navigateRoute = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
    msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(msg)
  }
  functionTokenCheck=async ()=>{
  let authToken =localStorage.getItem("token")
  if (!authToken ) {
    window.localStorage.clear();
    this.navigateRoute()
  }
  }

  // Customizable Area End
}
